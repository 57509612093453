<template>
  <div class="tupu" style="padding-top:60px;">
    <!-- <FangziHeadTwo ttPass="关联图谱"></FangziHeadTwo> -->
    <div style="overflow: hidden;">
      <div >
        <div style="margin:0 105px;">
          <el-row :gutter="10">
  <el-col :span="8"><div class="grid-content bg-purple" style="height: calc(100vh - 220px);background-color: white;position: relative;">
    <!-- <el-input style="position:absolute;top:60px;left:0;width:80%;left:10%;z-index:100;"></el-input> -->
    <el-autocomplete
 style="width:660px;position:absolute;top:60px;left:0;width:80%;left:10%;z-index:100;"
v-model.trim="state"
:fetch-suggestions="querySearch"
placeholder="请输入企业名称关键字"
@select="handleSelect"
:disabled="!common.getSessionStorage('userInfo')"
></el-autocomplete>
    <el-tabs v-model="activeName" :stretch="true" style="background: #fff" type="card" @tab-click="handleClick">
            <el-tab-pane label="企业关系网" name="first">
             <div class="z1_ipt">
              <div style="overflow: auto;">
                <el-form ref="form" :model="form" label-width="80px">
  <el-form-item label="选择人">
    <el-radio-group v-model="form.selectId" @change="changeSelectId">
      <el-radio v-for="item in peopleList" :key="item.shareholderId" :label="item.shareholderId">{{item.shareholderName}}</el-radio>
    </el-radio-group>
  </el-form-item>
  <el-form-item label="展现形式">
    <el-radio-group v-model="form.type">
      <el-radio key="1" label="1">全貌</el-radio>
      <el-radio key="2" label="2">播放</el-radio>
    </el-radio-group>
  </el-form-item>
  <el-form-item label="查询层级">
    <el-radio-group v-model="form.level">
      <el-radio key="1" label="1">1</el-radio>
      <el-radio key="2" label="2">2</el-radio>
      <el-radio key="3" label="3">3</el-radio>
    </el-radio-group>
  </el-form-item>
  <el-form-item label="企业关系">
    <el-checkbox-group v-model="form.enterpriseRelate">
      <el-checkbox key="1" label="1" name="type">企业股东</el-checkbox>
      <el-checkbox key="2" label="2" name="type">企业对外投资</el-checkbox>
    </el-checkbox-group>
  </el-form-item>
  <el-form-item label="人员关系">
    <el-checkbox-group v-model="form.personRelate">
      <el-checkbox key="1" label="1" name="type">自然人股东</el-checkbox>
      <el-checkbox key="2" label="2" name="type">自然人对外投资</el-checkbox>
      <el-checkbox key="3" label="3" name="type">公司管理人员</el-checkbox>
      <el-checkbox key="4" label="4" name="type">管理人员其他公司任职</el-checkbox>
    </el-checkbox-group>
  </el-form-item>
</el-form>
              </div>
              <el-button type="primary" @click="getGraphOne" :disabled="isLoading" class="tp">查看图谱</el-button>
             </div>
            </el-tab-pane>
            <el-tab-pane label="实际控制人" name="second">
              <div class="z1_ipt">
                <el-button type="primary" @click="getGraphTwo" :disabled="isLoading" class="tp">查看图谱</el-button>
            </div>
            </el-tab-pane>
            <el-tab-pane label="股权穿透图" name="third">
              <div class="z1_ipt">
                <el-button type="primary" @click="getGraphThree" :disabled="isLoading" class="tp">查看图谱</el-button>
            </div>
            </el-tab-pane>
          </el-tabs>
  </div></el-col>
  <el-col :span="16"><div class="grid-content bg-purple-light" style="height: calc(100vh - 220px);background-color: white;position:relative;">
    <div v-show="activeName=='first'"  id="containerDialogOne" style="background: #FBFBFB;height: 100%;" ref="containerDialogOne">
    </div>
    <div v-show="activeName=='first'" class="tuli" style="position:absolute;bottom:0px;right:0px;width:140px;height:50px;padding-bottom:10px;background-color: white;text-align:center;">
<div style="height:20px;">
  <span class="qianglian">
    </span>
    <span class="linkname">
企业
    </span>
    <span class="ruolian">
</span>
<span class="linkname">
人员
</span>
</div>
<div>
  <span class="touzi">
    </span>
    <span class="linkname">
投资
    </span>
    <span class="renzhi">
</span>
<span class="linkname">
任职
</span>
</div>
    </div>
    <div v-show="activeName=='second'"  id="containerDialogTwo" style="background: #FBFBFB;height: 100%;" ref="containerDialogTwo"></div>
    <div v-show="activeName=='third'"  id="containerDialogThree" style="background: #FBFBFB;height: 100%;" ref="containerDialogThree"></div>
  </div></el-col>
</el-row>
        </div>
    </div>
    </div>
    <el-dialog
  title="企业信息"
  :visible.sync="dialogVisibleCompany"
  width="30%"
  :append-to-body="true"
  >
  <p class="dl_title">{{companyObj.enterpriseInfo.name}}</p>
  <el-descriptions title="" :column="1">
    <el-descriptions-item label="评分">
      <div class="h2 flex pgs" style="text-align: center;">
          <div class="flex-1 mr22">
            <el-progress type="circle" width="37"  :percentage="parseInt(companyObj.enterpriseInfo.strength) " stroke-width="5"  :format="format" ></el-progress>
            <!-- <div>综合实力</div> -->
          </div>
          <div class="flex-1 mr22">
            <el-progress type="circle" width="37" :percentage="parseInt(companyObj.enterpriseInfo.innovation)" stroke-width="5"  :format="format"  ></el-progress>
            <!-- <div>创新能力</div> -->
          </div>
          <div class="flex-1 mr22">
            <el-progress type="circle" width="37" :percentage="parseInt(companyObj.enterpriseInfo.legal)" stroke-width="5"  :format="format"></el-progress>
            <!-- <div>合法合规</div> -->
          </div>
        </div>
    </el-descriptions-item>
    <el-descriptions-item label="企业官网">{{companyObj.enterpriseInfo.website}}</el-descriptions-item>
    <el-descriptions-item label="注册资本"> {{ companyObj.enterpriseInfo.regAmount==-1?'暂无数据':companyObj.enterpriseInfo.regAmount+"万元" }}</el-descriptions-item>
    <el-descriptions-item label="成立时间">{{companyObj.enterpriseInfo.setupDate}}</el-descriptions-item>
</el-descriptions>
  <el-tabs type="border-card">
  <el-tab-pane label="股东">
    <el-table
      :data="companyObj.holderList"
      style="width: 100%">
      <el-table-column
        prop="shareholderName"
        label="名称"
        width="320">
      </el-table-column>
      <el-table-column
        prop="investRate"
        label="持股比例">
      </el-table-column>
    </el-table>
  </el-tab-pane>
  <el-tab-pane label="对外投资">
    <el-table
      :data="companyObj.investList"
      style="width: 100%">
      <el-table-column
        prop="enterpriseName"
        label="名称"
        width="320">
      </el-table-column>
      <el-table-column
        prop="investRate"
        label="持股比例">
      </el-table-column>
    </el-table>
  </el-tab-pane>
  <el-tab-pane label="主要管理人员">
    <el-table
      :data="companyObj.managerList"
      style="width: 100%">
      <el-table-column
        prop="managerName"
        label="名称"
        width="320">
      </el-table-column>
      <el-table-column
        prop="managerJob"
        label="职位">
      </el-table-column>
    </el-table>
  </el-tab-pane>
</el-tabs>
  <!-- <span>{{JSON.stringify(companyObj)}}</span> -->
  <span slot="footer" class="dialog-footer">
  </span>
</el-dialog>
<el-dialog
  title="人员信息"
  :visible.sync="dialogVisiblePerson"
  width="30%"
  :append-to-body="true"
  >
  <p class="dl_title">{{personObj.enterprisePerson.name}}</p>
  <el-tabs type="border-card">
  <el-tab-pane label="对外投资">
    <el-table
      :data="personObj.investList"
      style="width: 100%">
      <el-table-column
        prop="enterpriseName"
        label="名称"
        width="320">
      </el-table-column>
      <el-table-column
        prop="investRate"
        label="持股比例">
      </el-table-column>
    </el-table>
  </el-tab-pane>
  <el-tab-pane label="主要管理人员">
    <el-table
      :data="personObj.managerList"
      style="width: 100%">
      <el-table-column
        prop="enterpriseName"
        label="名称"
        width="320">
      </el-table-column>
      <el-table-column
        prop="managerJob"
        label="职位">
      </el-table-column>
    </el-table>
  </el-tab-pane>
</el-tabs>
  <!-- <span>{{JSON.stringify(personObj)}}</span> -->
  <span slot="footer" class="dialog-footer">
  </span>
</el-dialog>
<!-- <div style="width:400px;height:400px;background-color: white;" id="demo">
</div> -->
  </div>
</template>
<script>
import companytab from '../../../components/map/companytab'
// import  {holdRelateList,holderRelateList,conditionRelateList,  holderEnterpriseInfo,holderPersonInfo,holderRelateSelectList} from '../../../api/map/huaxiang'
import  {holdRelateList,holderRelateList,conditionRelateList,  holderEnterpriseInfo,holderPersonInfo,holderRelateSelectList} from "../../../request/moudle/map/huaxiang"
// import {enterpriseSearchName} from '../../../api/map/common'
import {enterpriseSearchName} from "../../../request/moudle/map/common"
import G6 from '@antv/g6';
import FangziHeadTwo from "../../../components/zkwl/FangziHeadTwo.vue";
// import {NetChart} from "@dvsl/zoomcharts"//这样是收费版
import ZoomCharts from "../../../utils/zoomcharts.js"//破解
console.log('对象',ZoomCharts);


export default {
  name: "gltupu",
  data(){
    return {
      activeName:'first',//激活项
      nowId:null,//公司id
      nowName:null,//公司名称
      /////////////////
      graphOne:null,//图1
      graphTwo:null,//图2
      graphThree:null,//图2
      dialogVisibleCompany:false,//公司对话框
      dialogVisiblePerson:false,//个人对话框
      companyObj:{
        enterpriseInfo:{},
      },//公司信息
      personObj:{
        enterprisePerson:{}
      },//个人信息
      form:{
        selectId:null,
        type:'1',
        level:'1',
        enterpriseRelate:['1'],
        personRelate:['1','2','3','4'],
        selectType:null,
      },
      peopleList:[],//人员列表
      nowInterval:null,//定时器
            //
            state: '',
      timeout: null,
      suggestions: [],
      isLoading:false,
      // relationData: {
      //   // 第一层
      //   id: 'root',
      //   type: 'rect',
      //   label: '@@@@@有限公司',
      //   children: [{
      //     // 第二层
      //     id: '1',
      //     label: '下',
      //     children: [
      //       {
      //         id: '1-1',
      //         label: '@@@@@@@有限公司',
      //         money: '3,283.456',
      //         percent: 23,
      //       },
      //       {
      //         id: '1-2',
      //         label: '@@@@@@@有限公司',
      //         money: '3,283.456',
      //         percent: 23,
      //       },
      //       {
      //         id: '1-3',
      //         label: '@@@@@@@有限公司',
      //         money: '3,283.456',
      //         percent: 23,
      //       },
      //     ]
      //   }, {
      //     id: '2',
      //     label: '上',
      //     children: [
      //       {
      //         id: '2-1',
      //         name: '李易峰',
      //         shares: '3,283.456',
      //         percent: 23,
      //       },
      //       {
      //         id: '2-2',
      //         name: '李易峰',
      //         shares: '3,283.456',
      //         percent: 23,
      //       },
      //       {
      //         id: '2-3',
      //         name: '李易峰',
      //         shares: '3,283.456',
      //         percent: 23,
      //       },
      //     ]
      //   }]
      // },
    }
  },
  props: {
    typeson: {
      type: String,
      default: 'first'
    },

  },
  created(){
    this.activeName=this.typeson;
  },
  mounted() {
    this.initPage()
    // this.$bus.$on("refresh_Huaxiang", (val) => {
	  //     console.log(val)   // 0.7022180283884656
    //     // this.$message.error('跳转');
    //     this.initPage();
	  //   });
  //   const {id,name,type}= this.$route.query;
  // //  if(type!=undefined&&type!=null){
  // //   this.activeName=type;
  // //  }else{
  // //   this.activeName='first'
  // //  }
  //  if(id!=undefined&&id!=null){
  //   this.nowId=id;
  //  }
  //  if(name!=undefined&&name!=null){
  //   this.nowName=name;
  //  }
  //  //查询人
  //  if(id!=undefined&&id!=null){
  //   this.getPersonList();
  //  }
    // this.initZoom();
  },
  components:{
    companytab,
    FangziHeadTwo,
  },
  methods:{
    initZoom(dataP){
//       const data={
//     "nodes":[
//         {"id":"m-1", "age":20, "name":"Joezzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz", "loaded":true},
//         {"id":"m-2", "age":15, "name":"Fred", "loaded":true},
//         {"id":"m-3", "age":16, "name":"Tom", "loaded":true},
//         {"id":"m-4", "age":35, "name":"Robert", "loaded":true},
//         {"id":"m-5", "age":38, "name":"Mark", "loaded":true},
//         {"id":"m-6", "age":42, "name":"Jason", "loaded":true},
//         {"id":"m-7", "age":37, "name":"Bill", "loaded":true},
//         {"id":"m-8", "age":60, "name":"Andre", "loaded":true},
//         {"id":"m-9", "age":63, "name":"Daniel", "loaded":true},
//         {"id":"m-10", "age":17, "name":"Thomas", "loaded":true},
//         {"id":"m-11", "age":21, "name":"Sergejs", "loaded":true},
//         {"id":"m-12", "age":26, "name":"Bryon", "loaded":true},
//         {"id":"m-13", "age":29, "name":"Toby", "loaded":true},
//         {"id":"f-1", "age":28, "name":"Anna", "loaded":true},
//         {"id":"f-2", "age":21, "name":"Wendy", "loaded":true},
//         {"id":"f-3", "age":17, "name":"Dina", "loaded":true},
//         {"id":"f-4", "age":26, "name":"Cate", "loaded":true},
//         {"id":"f-5", "age":31, "name":"Elisa", "loaded":true},
//         {"id":"f-6", "age":34, "name":"Suzie", "loaded":true},
//         {"id":"f-7", "age":26, "name":"Trixie", "loaded":true},
//         {"id":"f-8", "age":37, "name":"Emily", "loaded":true},
//         {"id":"f-9", "age":39, "name":"Alice", "loaded":true},
//         {"id":"f-10", "age":42, "name":"Violet", "loaded":true},
//         {"id":"f-11", "age":32, "name":"Sara", "loaded":true},
//         {"id":"f-12", "age":28, "name":"Julia", "loaded":true},
//         {"id":"f-13", "age":19, "name":"Ramona", "loaded":true},
//         {"id":"f-14", "age":20, "name":"Flavia", "loaded":true},
//         {"id":"f-15", "age":23, "name":"Liga", "loaded":true},
//         {"id":"f-16", "age":27, "name":"Jessica", "loaded":true},
//         {"id":"f-17", "age":40, "name":"Barbara", "loaded":true},
//         {"id":"f-18", "age":45, "name":"Hanna", "loaded":true},
//         {"id":"f-19", "age":53, "name":"Giselle", "loaded":true},
//         {"id":"f-20", "age":27, "name":"Mia", "loaded":true},
//         {"id":"f-21", "age":19, "name":"Rose", "loaded":true},
//         {"id":"f-23", "age":28, "name":"Judy", "loaded":true},
//         {"id":"f-22", "age":32, "name":"Nikola", "loaded":true},
//         {"id":"f-24", "age":34, "name":"Sofia", "loaded":true},
//         {"id":"f-25", "age":37, "name":"Fatima", "loaded":true},
//         {"id":"f-26", "age":44, "name":"Samantha", "loaded":true},
//         {"id":"f-27", "age":23, "name":"Chelia", "loaded":true},
//         {"id":"f-28", "age":18, "name":"Alexa", "loaded":true},
//         {"id":"f-29", "age":21, "name":"Karla", "loaded":true},
//         {"id":"f-30", "age":23, "name":"Karina", "loaded":true},
//         {"id":"f-31", "age":51, "name":"Patricia", "loaded":true},
//         {"id":"f-32", "age":47, "name":"Anna", "loaded":true},
//         {"id":"f-33", "age":38, "name":"Laura", "loaded":true}
//    ],
//     "links":[
//         {"id":"l01", "from":"m-1", "to":"f-1", "type":"friend"},
//         {"id":"l02", "from":"m-1", "to":"f-2", "type":"friend"},
//         {"id":"l03", "from":"m-1", "to":"f-3", "type":"friend"},
//         {"id":"l04", "from":"m-1", "to":"f-4", "type":"friend"},
//         {"id":"l06", "from":"m-1", "to":"f-6", "type":"friend"},
//         {"id":"l07", "from":"m-2", "to":"f-2", "type":"collegue"},
//         {"id":"l12", "from":"m-3", "to":"f-10", "type":"spouse"},
//         {"id":"l13", "from":"m-3", "to":"f-5", "type":"enemy"},
//         {"id":"l14", "from":"m-3", "to":"f-8", "type":"friend"},
//         {"id":"l15", "from":"m-3", "to":"f-4", "type":"friend"},
//         {"id":"l16", "from":"m-3", "to":"f-9", "type":"friend"},
//         {"id":"l17", "from":"m-4", "to":"f-15", "type":"spouse"},
//         {"id":"l18", "from":"m-4", "to":"f-14", "type":"collegue"},
//         {"id":"l22", "from":"m-5", "to":"f-15", "type":"collegue"},
//         {"id":"l23", "from":"m-5", "to":"f-4", "type":"collegue"},
//         {"id":"l27", "from":"f-11", "to":"f-15", "type":"collegue"},
//         {"id":"l28", "from":"m-5", "to":"m-6", "type":"friend"},
//         {"id":"l29", "from":"m-6", "to":"m-7", "type":"friend"},
//         {"id":"l30", "from":"m-7", "to":"m-8", "type":"friend"},
//         {"id":"l31", "from":"m-8", "to":"m-9", "type":"friend"},
//         {"id":"l32", "from":"m-9", "to":"m-10", "type":"friend"},
//         {"id":"l33", "from":"m-10", "to":"m-11", "type":"friend"},
//         {"id":"l34", "from":"m-11", "to":"m-12", "type":"friend"},
//         {"id":"l35", "from":"m-12", "to":"m-13", "type":"friend"},
//         {"id":"l36", "from":"m-13", "to":"m-5", "type":"friend"},
//         {"id":"l101", "from":"m-7", "to":"f-25", "type":"collegue"},
//         {"id":"l102", "from":"m-7", "to":"f-26", "type":"collegue"},
//         {"id":"l103", "from":"m-9", "to":"f-26", "type":"collegue"},
//         {"id":"l104", "from":"m-9", "to":"f-25", "type":"collegue"},
//         {"id":"l105", "from":"f-25", "to":"f-26", "type":"collegue"},
//         {"id":"l106", "from":"m-7", "to":"m-9", "type":"collegue"},
//         {"id":"l107", "from":"f-26", "to":"f-28", "type":"friend"},
//         {"id":"l108", "from":"f-27", "to":"f-28", "type":"friend"},
//         {"id":"l109", "from":"f-27", "to":"f-29", "type":"friend"},
//         {"id":"l110", "from":"f-10", "to":"f-29", "type":"friend"},
//         {"id":"l111", "from":"f-29", "to":"f-33", "type":"friend"},
//         {"id":"l112", "from":"f-29", "to":"f-32", "type":"friend"},
//         {"id":"l113", "from":"f-29", "to":"f-31", "type":"friend"},
//         {"id":"l114", "from":"f-24", "to":"f-31", "type":"friend"},
//         {"id":"l115", "from":"f-24", "to":"f-32", "type":"friend"},
//         {"id":"l116", "from":"f-24", "to":"f-33", "type":"friend"},
//         {"id":"l117", "from":"f-24", "to":"f-23", "type":"friend"},
//         {"id":"l118", "from":"f-23", "to":"f-22", "type":"friend"},
//         {"id":"l119", "from":"f-22", "to":"f-21", "type":"friend"},
//         {"id":"l120", "from":"f-21", "to":"f-20", "type":"friend"},
//         {"id":"l121", "from":"f-20", "to":"f-19", "type":"friend"},
//         {"id":"l122", "from":"f-19", "to":"f-18", "type":"friend"},
//         {"id":"l123", "from":"f-18", "to":"f-17", "type":"friend"},
//         {"id":"l124", "from":"f-17", "to":"f-16", "type":"friend"},
//         {"id":"l125", "from":"f-16", "to":"f-30", "type":"friend"},
//         {"id":"l126", "from":"f-19", "to":"f-30", "type":"friend"},
//         {"id":"l130", "from":"f-15", "to":"m-10", "type":"friend"},
//         {"id":"l131", "from":"f-23", "to":"m-4", "type":"friend"},
//         {"id":"l132", "from":"f-15", "to":"m-7", "type":"friend"},
//         {"id":"l133", "from":"f-12", "to":"m-13", "type":"friend"},
//         {"id":"l134", "from":"f-21", "to":"m-12", "type":"friend"},
//         {"id":"l135", "from":"f-29", "to":"m-11", "type":"friend"},
//         {"id":"l136", "from":"f-13", "to":"m-11", "type":"friend"},
//         {"id":"l137", "from":"f-13", "to":"m-7", "type":"friend"},
//         {"id":"l138", "from":"f-13", "to":"m-12", "type":"friend"},
//         {"id":"l139", "from":"f-13", "to":"m-6", "type":"friend"},
//        {"id":"l140", "from":"f-17", "to":"f-9", "type":"friend"},
//        {"id":"l141", "from":"f-2", "to":"m-4", "type":"collegue"},
//        {"id":"l142", "from":"f-5", "to":"m-13", "type":"friend"},
//        {"id":"l143", "from":"f-7", "to":"f-20", "type":"friend"}
//     ]
// };
const nameKing=dataP.nodes.length>0? dataP.nodes[0].name:'';
// alert(nameKing);
      var t = new ZoomCharts.NetChart({
            container: document.getElementById("containerDialogOne"),
            area: { height: 'auto' },
            data:{preloaded:dataP},
            // data:{preloaded:data},
            // navigation: { initialNodes: [nameKing], mode: "focusnodes" },
            // navigation: { initialNodes: ["m-1"], mode: "focusnodes" },
            style: {
                nodeStyleFunction: nodeStyle,
                linkStyleFunction: linkStyle,
                linkLabel:{
                padding: 0,
                borderRadius: 999,  //make as round as possible
                textStyle:{font:"12px Arial", fillColor: "black"},
                backgroundStyle:{fillColor:"rgba(255,255,255,0.9)", lineColor:"rgba(0,0,0,0.9)"}
            },
            }
        });
        function nodeStyle(node) {
          const enumC={
            '0':process.env.VUE_APP_BASE_URL+'map/zself.png',
            '1':process.env.VUE_APP_BASE_URL+'map/zothers.png',
            '2':process.env.VUE_APP_BASE_URL+'map/zpeople.png'
          };
            node.label = node.data.name;
            // node.image='/map/zself.png';
            node.image=enumC[node.data.category];
            node.width=30;
        }
        function linkStyle(link) {
            // link.fromDecoration = "circle";
            link.toDecoration = "arrow";
            // link.label=link.data.type;
            link.label=link.data.value.type+' '+link.data.value.val;
            link.fillColor=link.data.value.type==''?'rgb(5, 213, 227)':'rgb(72, 154, 220)';
        }
    },
    //切换tab栏
    handleClick(){
      //有定时器就关闭
      if(this.nowInterval!=null){
    clearInterval(this.nowInterval);
    this.nowInterval=null;
  }
    },
    initPage(){
        const obj= sessionStorage.getItem('parampass_huaxiang')
   if(obj==undefined){
   }else{
    this.ss_huaxiang=JSON.parse(obj);
   }
   console.log('参数',this.ss_huaxiang);
   if(this.ss_huaxiang!=null){
    this.activeName=this.ss_huaxiang.sontype==undefined?'first':this.ss_huaxiang.sontype;
    this.nowId=this.ss_huaxiang.id;
      //  //查询人
   if(this.nowId!=undefined&&this.nowId!=null){
    this.getPersonList();
    this.state=this.ss_huaxiang.name;
    //直接查结果
    const tp=this.ss_huaxiang.type;
    if(tp==1){
      this.getGraphOne();
    }else if(tp==2){
      this.getGraphTwo();
    }else if(tp==3){
      this.getGraphThree();
    }
   }

   //


   }
      // this.$store.commit("CHANGE_TABTYPE", 2);
      // const type=this.$route.query.type;
      // if(type!=undefined){
      //   this.activeTab=type;
      // }
      },
     //格式化进度
     format(val){
      return parseInt(val)
    },
    //查询
    querySearch(queryString, cb) {
      const that=this;
      // 清除定时器
      clearTimeout(this.timeout);
      if(that.state==''){
        return;
      }
      this.timeout = setTimeout(() => {
        // 模拟从后台获取数据的异步操作
        enterpriseSearchName({key:queryString}).then(res=>{
          const names=res.data.map(item=>{
            return {value:item.name,id:item.id} ;
          })
            that.suggestions = names;
      //       // 调用 callback 返回建议列表的数据
            cb(that.suggestions);
        }).catch(err=>{
          cb([]);
        });
      }, 500); // 延迟200毫秒请求，防止密集的请求
      // this.timeout = setTimeout(() => {
      //   // 模拟从后台获取数据的异步操作
      //   axios.get('your-api-url', { params: { q: queryString } })
      //     .then(response => {
      //       // 假设返回的数据格式为 { data: ['Option 1', 'Option 2', ...] }
      //       this.suggestions = response.data.data;
      //       // 调用 callback 返回建议列表的数据
      //       cb(this.suggestions);
      //     })
      //     .catch(error => {
      //       console.error(error);
      //       cb([]);
      //     });
      // }, 200); // 延迟200毫秒请求，防止密集的请求
    },
    handleSelect(item) {
      // 选中某项时的处理逻辑
      this.nowId=item.id;
      this.nowName=item.name;
      this.form.selectId=null;
      this.getPersonList();
    },
    changeSelectId(){
      const obj=this.peopleList.find(item=>{
        return item.shareholderId==this.form.selectId
      })
      console.log('选中项',obj);
      this.form.selectType=obj.shareholderFlag
    },
    //查询人列表
    getPersonList(){
      this.peopleList=[];
      holderRelateSelectList({id:this.nowId}).then(res=>{
        const res2=res.data;
        this.peopleList=res2;
      }).catch()
    },
    //获取公司信息
    getCompanyInfo(id){
      this.companyObj={
        enterpriseInfo:{}
      };
      holderEnterpriseInfo({id}).then(res=>{
        const res2=res.data;
        this.companyObj=res2;
      }).catch();
    },
    //获取人员信息
    getPersonInfo(id){
      this.personObj={
        enterprisePerson:{}
      };
      holderPersonInfo({id}).then(res=>{
        const res2=res.data;
        this.personObj=res2;
      }).catch();
    },
    //获取图三数据
    getGraphThree(){
      if(this.nowId==null){
        this.$message.error('请输入并选择公司');
        return;
      }
      this.isLoading=true;
      if(this.graphThree!=null){
        this.graphThree.destroy();
        this.graphThree=null;
      }
      holdRelateList({id:this.nowId}).then(res=>{
        this.isLoading=false;
        let res2=res.data;
        // this.initGraphTwo(this.relationData);
        let $ele2 = this.$refs.containerDialogThree
// 宽
let width2 = $ele2.offsetWidth
// 高
let height2 = $ele2.offsetHeight
        this.initGraphThree(res2,width2,height2);
      }).catch(err=>{
        this.isLoading=false;
      });
    },
    //获取图二数据
    getGraphTwo(){
      if(this.nowId==null){
        this.$message.error('请输入并选择公司');
        return;
      }
      this.isLoading=true;
      if(this.graphTwo!=null){
        this.graphTwo.destroy();
        this.graphTwo=null;
      }
      holderRelateList({id:this.nowId}).then(res=>{
        this.isLoading=false;
        let res2=res.data;
        // this.initGraphTwo(this.relationData);
        let $ele2 = this.$refs.containerDialogTwo
// 宽
let width2 = $ele2.offsetWidth
// 高
let height2 = $ele2.offsetHeight
        this.initGraphTwo(res2,width2,height2);
      }).catch(err=>{
        this.isLoading=false;
      });
    },
    //获取图一数据
    getGraphOne(){
      if(this.nowInterval!=null){
    clearInterval(this.nowInterval);
    this.nowInterval=null;
  }
      // if(this.graphOne!=null){
      //   this.graphOne.destroy();
      //   this.graphOne=null;
      // }
      if(this.nowId==null){
        this.$message.error('请输入并选择公司');
        return;
      }
      this.isLoading=true;
        //清空图
        // this.$echarts.dispose(this.$refs.containerDialogOne);
      conditionRelateList({...this.form,...{enterpriseId:this.nowId}}).then(res=>{
       this.isLoading=false;
        const res2=res.data;

        res2.nodes=res2.nodes.map(item=>{
          return {...item,...{name:nameAB(item.name) ,category:item.category.toString()}}
        });
        res2.links=res2.links.map(item=>{
         return {...item,...{from:item.source,to:item.target}}
        });

        function nameAB(name){
          function addNewlines(str, interval) {
  // return str.replace(/(.{6})/g, '$1\n\r');
  return str.replace(/(.{6})/g, '$1 ');
}
return addNewlines(name, 6);
        }
        if(res2.nodes.length>0){
          if(this.form.type!=2){
            this.initZoom(res2)
        }else{
          //动态的
          if(this.nowInterval!=null){
            clearInterval(this.nowInterval);
          }
          //动态渲染关系图
          // this.initGraphOneDynamic(res2);
          this.initZoomDynamic(res2);
          //
        }
        }else{
          this.$message.warning("此企业暂无数据")
        }
      }).catch(err=>{
        this.isLoading=false;
      });
    },
    //动态播放
    initZoomDynamic(dataP){

      let that=this;
      const nodes=dataP.nodes;
      const links=dataP.links;
      let dataDynamic=[nodes[0]];
      let edgesDynamic=[];
      //先绘制一个节点
      //////////////////////////////////////////////////////////////////////
      const nameKing=dataP.nodes.length>0? dataP.nodes[0].name:'';
// alert(nameKing);

      var tD = new ZoomCharts.NetChart({
            container: document.getElementById("containerDialogOne"),
            area: { height: 'auto' },
            data:{preloaded:{nodes:dataDynamic,links:edgesDynamic}},
            // data:{preloaded:data},
            // navigation: { initialNodes: [nameKing], mode: "focusnodes" },
            // navigation: { initialNodes: ["m-1"], mode: "focusnodes" },
            style: {
                nodeStyleFunction: nodeStyle,
                linkStyleFunction: linkStyle,
                linkLabel:{
                padding: 0,
                borderRadius: 999,  //make as round as possible
                textStyle:{font:"12px Arial", fillColor: "black"},
                backgroundStyle:{fillColor:"rgba(255,255,255,0.9)", lineColor:"rgba(0,0,0,0.9)"}
            },
            }
        });
        function nodeStyle(node) {
          const enumC={
            '0':process.env.VUE_APP_BASE_URL+'map/zself.png',
            '1':process.env.VUE_APP_BASE_URL+'map/zothers.png',
            '2':process.env.VUE_APP_BASE_URL+'map/zpeople.png'
          };
            node.label = node.data.name;
            // node.image='/map/zself.png';
            node.image=enumC[node.data.category];
            node.width=30;
        }
        function linkStyle(link) {
            // link.fromDecoration = "circle";
            link.toDecoration = "arrow";
            // link.label=link.data.type;
            link.label=link.data.value.type+' '+link.data.value.val;
            link.fillColor=link.data.value.type==''?'rgb(5, 213, 227)':'rgb(72, 154, 220)';
        }
      //////////////////////////////////////////////////////////////////////
      if(nodes.length<2){
  //一共一个节点
  return;
}
let i=1;
this.nowInterval= setInterval(function () {
  if(nodes.length==2){
    //一共两个节点
    dataDynamic.push(nodes[1]);
    edgesDynamic=links;
    tD.addData({nodes:[nodes[1]],links:links});
  //   myCharts.setOption({
  //   series: [
  //     {
  //       roam: true,
  //       data: dataDynamic,
  //       edges: edgesDynamic
  //     }
  //   ]
  // });
  that.$message.success('播放结束');
    clearInterval(that.nowInterval);
    that.nowInterval=null;
    return;
  }
  //大于等于三个节点
  let remainLinks=links;
  if(i==nodes.length){
    that.$message.success('播放结束');
    clearInterval(that.nowInterval);
    that.nowInterval=null;
  }
  if(i==nodes.length-1){
    //最后一个点
    dataDynamic.push(nodes[i]);
    edgesDynamic=edgesDynamic.concat(remainLinks);
    tD.addData({nodes:[nodes[i]],links:remainLinks});
  //   myCharts.setOption({
  //   series: [
  //     {
  //       roam: true,
  //       data: dataDynamic,
  //       edges: edgesDynamic
  //     }
  //   ]
  // });
  that.$message.success('播放结束');
    clearInterval(that.nowInterval);
    that.nowInterval=null;
    return;
  }else{
    //中间点
    dataDynamic.push(nodes[i]);
    let condition=[];
    const ids=dataDynamic.map(item=>{
      return item.id;
    })
    let temLk=[];
    remainLinks.forEach(item=>{
            if(ids.indexOf(item.target)!=-1&&ids.indexOf(item.source)!=-1){
        edgesDynamic.push(item);
        temLk.push(item)
      }else{
        condition.push(item);
      }
    })
    remainLinks=condition;//把符合的数据拿走了，数组越来越小

    tD.addData({nodes:[nodes[i]],links:temLk});
  //   myCharts.setOption({
  //   series: [
  //     {
  //       roam: true,
  //       data: dataDynamic,
  //       edges: edgesDynamic
  //     }
  //   ]
  // });
    i+=1;//最后加一
    //
  }
}, 600);
    },
    //动态关系图
    initGraphOneDynamic(webkitDep) {
      let that=this;
      // this.$message.error('动态');
      const imgsEnum={
        '0':'image:'+process.env.VUE_APP_BASE_URL+'/map/zself.png',
        '1':'image:'+process.env.VUE_APP_BASE_URL+'/map/zothers.png',
        '2':'image:'+process.env.VUE_APP_BASE_URL+'/map/zpeople.png'
      };
      const nodes=webkitDep.nodes.map(item=>{
        return {...item,...{symbolSize:40,symbol: imgsEnum[item.category.toString()]}}
      })
      const lineEnum={
        '1':'#489adc',
        '2':'#05d5e3',
      }
      const links=webkitDep.links.map(item=>{
        const obj={...item,...{lineStyle:{
          curveness:item.radian==undefined?0:item.radian*0.1,color:lineEnum[item.value.lineType],width:2
        }}}
        return obj;
      })
      if(nodes.length==0){
        return;
      }
      let dataDynamic=[nodes[0]];
      let edgesDynamic=[];
     const  option = {
      title: {
      text: '',
      subtext: '',
      top: 'bottom',
      left: 'right'
    },
    tooltip: {},
    legend: [
      // {
      //   // selectedMode: 'single',
      //   data: webkitDep.categories.map(function (a) {
      //     return a.name;
      //   })
      // }
    ],
      animation:false,
    series: [
      {
        name: '',
        type: 'graph',
        layout: 'force',
        data: dataDynamic,
        links: edgesDynamic,
        categories: webkitDep.categories,
        roam: true,
        // draggable:true,
        label: {//图形上的文本标签，可用于说明图形的一些数据信息
    normal: {
        show : true,//显示
        position: 'bottom',//相对于节点标签的位置，默认在节点中间
        //回调函数，你期望节点标签上显示什么
        formatter: function(params){
          const name=params.data.name;
          function addNewlines(str, interval) {
  return str.replace(/(.{6})/g, '$1\n');
}
return addNewlines(name, 6);
        },
    }
},
edgeSymbol: ['circle', 'arrow'],
        edgeSymbolSize: [4, 10],
        edgeLabel: {//线条的边缘标签
          textStyle: {
                fontSize: 10 // 设置边标签的字体大小
            },
    normal: {
        show: true,
        //通过回调函数设置连线上的标签
        formatter: function (x) {
            return x.value.type+ x.value.val;
        }
    }
},
        force: {
          repulsion: 3000,
          edgeLength:150,
          layoutAnimation:false,
        }
      }
    ]
  };
  let  myCharts = this.$echarts.init(this.$refs.containerDialogOne);
myCharts.setOption(option);
//
if(nodes.length<2){
  //一共一个节点
  return;
}
let i=1;
this.nowInterval= setInterval(function () {
  if(nodes.length==2){
    //一共两个节点
    dataDynamic.push(nodes[1]);
    edgesDynamic=links;
    myCharts.setOption({
    series: [
      {
        roam: true,
        data: dataDynamic,
        edges: edgesDynamic
      }
    ]
  });
  that.$message.success('播放结束');
    clearInterval(that.nowInterval);
    that.nowInterval=null;
    return;
  }
  //大于等于三个节点
  let remainLinks=links;
  if(i==nodes.length){
    that.$message.success('播放结束');
    clearInterval(that.nowInterval);
    that.nowInterval=null;
  }
  if(i==nodes.length-1){
    //最后一个点
    dataDynamic.push(nodes[i]);
    edgesDynamic=edgesDynamic.concat(remainLinks);
    myCharts.setOption({
    series: [
      {
        roam: true,
        data: dataDynamic,
        edges: edgesDynamic
      }
    ]
  });
  that.$message.success('播放结束');
    clearInterval(that.nowInterval);
    that.nowInterval=null;
    return;
  }else{
    //中间点
    dataDynamic.push(nodes[i]);
    let condition=[];
    const ids=dataDynamic.map(item=>{
      return item.id;
    })
    remainLinks.forEach(item=>{
            if(ids.indexOf(item.target)!=-1&&ids.indexOf(item.source)!=-1){
        edgesDynamic.push(item);
      }else{
        condition.push(item);
      }
    })
    remainLinks=condition;//把符合的数据拿走了，数组越来越小
    myCharts.setOption({
    series: [
      {
        roam: true,
        data: dataDynamic,
        edges: edgesDynamic
      }
    ]
  });
    i+=1;//最后加一
    //
  }
}, 500);
    },
    //静态
    initGraphOne(webkitDep) {
      console.log('数据图',webkitDep.categories);
      const colorsEnum={
        '当前企业':'#ff6200',
        '目标企业':'#489adc',
        '目标人员':'#05d5e3'
      }
      const imgsEnum={
        '0':'image:'+process.env.VUE_APP_BASE_URL+'/map/zself.png',
        '1':'image:'+process.env.VUE_APP_BASE_URL+'/map/zothers.png',
        '2':'image:'+process.env.VUE_APP_BASE_URL+'/map/zpeople.png'
      };
      const nodes=webkitDep.nodes.map(item=>{
        return {...item,...{symbolSize:40,symbol:imgsEnum[ item.category.toString()]}}
        // return {...item,...{symbol: "circle",symbolSize:40,size:40,symbol:require( `@/assets/img/map/tp/cmpone.png`)}}
      })
      console.log('链接数据',webkitDep.links);
      const lineEnum={
        '1':'#489adc',
        '2':'#05d5e3',
      }
      const links=webkitDep.links.map(item=>{
        const obj={...item,...{lineStyle:{
          curveness:item.radian==undefined?0:item.radian*0.1,color:lineEnum[item.value.lineType],width:2
        }}}
        return obj;
      })
     const  option = {
      title: {
      text: '',
      subtext: '',
      top: 'bottom',
      left: 'right'
    },
    tooltip: {},
    legend: [
      // {
      //   // selectedMode: 'single',
      //   data: webkitDep.categories.map(function (a) {
      //     return a.name;
      //   })
      // }
    ],
      animation:false,
    series: [
      {
        name: '',
        type: 'graph',
        layout: 'force',
        data: nodes,
        links: links,
        categories: webkitDep.categories.map(item=>{
          console.log('颜色',item.name);
          return  {...item,itemStyle:{color:colorsEnum[item.name]}}
        }),
        roam: true,
        // draggable:true,
        label: {//图形上的文本标签，可用于说明图形的一些数据信息
    normal: {
        show : true,//显示
        position: 'bottom',//相对于节点标签的位置，默认在节点中间
        //回调函数，你期望节点标签上显示什么
        formatter: function(params){
          const name=params.data.name;
          function addNewlines(str, interval) {
  return str.replace(/(.{6})/g, '$1\n');
}
return addNewlines(name, 6);
        },
    }
},
edgeSymbol: ['circle', 'arrow'],
        edgeSymbolSize: [4, 10],
        edgeLabel: {//线条的边缘标签
          textStyle: {
                fontSize: 10 // 设置边标签的字体大小
            },
    normal: {
        show: true,
        layout: { // 设置标签水平
                    type: 'horizontal'
                },
        //通过回调函数设置连线上的标签
        formatter: function (x) {
            return x.value.type+ x.value.val;
        }
    }
},
        force: {
          repulsion: 2000,
          edgeLength:150,
          layoutAnimation:false,
        }
      }
    ]
  };
  const  myCharts = this.$echarts.init(this.$refs.containerDialogOne);
myCharts.setOption(option);
    },
    initGraphTwo(relationData,widthP,heightP) {
      // 对数据进行处理
      relationData.children.forEach(i => {
        // 上层节点的子节点都标注位置为上（用于设置不同样式）
        if (i.label === '上') {
          i.children.forEach(j => {
            j.position = '上'
            // 上层节点的连接点的位置
            j.anchorPoints = [
              [0.5, 0],
              [0.5, 1]
            ]
          })
        } else if (i.label === '下'){
          i.children.forEach(j => {
            j.position = '下'
          })
        }
      })
      /* 中间的根节点 */
      G6.registerNode('root', {
        draw: (cfg, group) => {
          // 设置样式
          const size = [150, 22]
          const keyShape = group.addShape('rect', {
            attrs: {
              width: size[0],
              height: size[1],
              x: -size[0] / 2,
              y: -size[1] / 2,
              fill: '#3388FF',
              radius: 5
            },
            draggable: true,
            name: 'root-keyshape'
          })
          // 根节点的文本样式
          group.addShape('text', {
            attrs: {
              text: `${cfg.label}`,
              fill: '#fff',
              fontSize: 9,
              x: 0,
              y: 4,
              textAlign: 'center'
            },
            draggable: true,
            name: 'label-shape'
          })
          return keyShape
        }
      })
      /* 第二层节点（其实相当于没用，隐藏了） */
      G6.registerNode('nonode', {
        draw: (cfg, group) => {
          const size = [0, 0]
          const keyShape = group.addShape('rect', {
            attrs: {
              width: size[0],
              height: size[1],
              x: -size[0] / 2,
              y: -size[1] / 2
            },
            draggable: true,
            name: 'root-keyshape'
          })
          return keyShape
        }
      })
      // 下层节点
      G6.registerNode('downnode', {
        draw: (cfg, group) => {
          const size = [140, 20]
          const keyShape = group.addShape('rect', {
            attrs: {
              width: size[0],
              height: size[1],
              x: -size[0] / 2,
              // y: -size[1] / 2,
              y: -size[1] ,
              fill: '#fff',
              lineWidth: 1,
              stroke: '#9DBCE3',
              radius: 1
            },
            draggable: true,
            name: 'level1node-keyshape'
          })
          /* 公司 */
          group.addShape('text', {
            attrs: {
              text: `${cfg.label}`,
              fill: 'rgba(0, 0, 0, 1)',
              fontSize: 7,
              x: 0,
              y: 0,
              textAlign: 'center'
            },
            draggable: true,
            name: 'label-shape'
          })
          // /* 认缴金额 */
          // group.addShape('text', {
          //   attrs: {
          //     text: `认缴金额:${cfg.label}万(元)`,
          //     fill: 'rgba(19, 33, 92, 0.65)',
          //     fontSize: 5,
          //     x: 0,
          //     y: 10,
          //     textAlign: 'center'
          //   },
          //   draggable: true,
          //   name: 'label-money'
          // })
          /* 百分比 */
          group.addShape('rect', {
            attrs: {
              x: 14,
              y: -16,
              width: 36,
              height: 8,
              radius: 3,
              fill: '#4C8CDD'
            },
            draggable: true,
            name: 'percent-box'
          })
          group.addShape('text', {
            attrs: {
              text: `占比${cfg.percent}%`,
              fill: '#fff',
              fontSize: 6,
              x: 32,
              y: -14,
              textAlign: 'center',
              textBaseline: 'top'
            },
            draggable: true,
            name: 'percent-shape'
          })
            /* 百分比 */
            group.addShape('rect', {
            attrs: {
              x: -50,
              y: -16,
              width: 36,
              height: 8,
              radius: 3,
              fill: '#4C8CDD'
            },
            draggable: true,
            name: 'percent-box2'
          })
          group.addShape('text', {
            attrs: {
              text: `${cfg.shares}万`,
              fill: '#fff',
              fontSize: 6,
              x: -32,
              y: -14,
              textAlign: 'center',
              textBaseline: 'top'
            },
            draggable: true,
            name: 'percent-shape2'
          })
          // edge end
          group.addShape('circle', {
            attrs: {
              r: 2,
              x: 0,
              y: -20,
              fill: 'rgb(19, 33, 92)'
            }
          })
          return keyShape
        },
        update: undefined
      }, 'rect')
      // 上层节点
      G6.registerNode('upnode', {
        draw: (cfg, group) => {
          const size = [80, 40]
          const keyShape = group.addShape('rect', {
            attrs: {
              width: size[0],
              height: size[1],
              x: -size[0] / 2,
              y: -size[1] / 2,
              fill: '#fff',
              lineWidth: 1,
              stroke: '#9DBCE3',
              radius: 1
            },
            draggable: true,
            name: 'level1node-keyshape'
          })
          group.addShape('text', {
            attrs: {
              text: `${cfg.label}`,
              fill: 'rgba(0, 0, 0, 1)',
              fontSize: 6,
              x: 0,
              y: -6,
              textAlign: 'center'
            },
            draggable: true,
            name: 'label-shape'
          })
          group.addShape('text', {
            attrs: {
              text: `持股数:${cfg.shares}股`,
              fill: 'rgba(0, 0, 0, 1)',
              fontSize: 6,
              x: 0,
              y: 4,
              textAlign: 'center'
            },
            draggable: true,
            name: 'sublabel-shape'
          })
          /* 百分比 */
          group.addShape('rect', {
            attrs: {
              x: -12,
              y: 8,
              width: 25,
              height: 8,
              radius: 4,
              fill: '#4C8CDD'
            },
            draggable: true,
            name: 'percent-box'
          })
          group.addShape('text', {
            attrs: {
              text: `${cfg.percent}%`,
              fill: 'rgba(255, 255, 255, 1)',
              fontSize: 6,
              x: 0,
              y: 9.5,
              textAlign: 'center',
              textBaseline: 'top'
            },
            draggable: true,
            name: 'percent-shape'
          })
          // edge end
          group.addShape('circle', {
            attrs: {
              r: 2,
              x: 0,
              y: 20,
              fill: 'rgb(19, 33, 92)'
            }
          })
          return keyShape
        },
        update: undefined
      }, 'rect')
      // 配置注册节点的样式
      G6.Util.traverseTree(relationData, subtree => {
        if (subtree.level === undefined) subtree.level = 0
        if(subtree.children){
          subtree.children.forEach(child => child.level = subtree.level + 1)
        }
        if (subtree.level === 0) {
          subtree.type = 'root'
        } else if (subtree.position === '上') {
          subtree.type = 'upnode'
        } else if (subtree.position === '下') {
          subtree.type = 'downnode'
        } else {
          subtree.type = 'nonode'
        }
      })
      // 画布的大小
      // const width = 1200
      // const height = 700
      const width = widthP
      const height = heightP
      // 创建g6 canvas实例
      const graph = new G6.TreeGraph({
        container: 'containerDialogTwo',
        width,
        height,
        // 自适应画布
        fitView: true,
        fitViewPadding:70,//140
        // 树状布局
        layout: {
          type: 'mindmap',//compactBox
          // ‘V’型才是上下
          direction: 'H',//sz方向
          // 节点横向间距的回调函数
          getHGap: function getVGap () {
            return 10
          },
          // 子节点的宽度
          getWidth: function getWidth () {
            return 65
          },
          // 层级之间的高度间距，！！重点：设置第二层的节点与根节点的间距为0
          getVGap: function getV (d) {
            if (d.id === 'root' || d.label === '上') {
              return 0
            } else {
              return 10
            }
          },
          // 子节点的高度
          getHeight: function getHeight () {
            return 4//sz高度差
          }
        },
        // 点之间的连线的样式
        defaultEdge: {
          type: 'cubic-vertical',
          sourceAnchor: 0,
          targetAnchor: 1,
          style: {
            radius: 80,
            stroke: '#BBCDE4'
          }
        },
        // 默认连接的位置
        defaultNode: {
          anchorPoints: [
            // 上是根节点 下是子节点的位置
            [0.5, 1],
            [0.5, 0]
          ]
        },
        // 鼠标移入样式
        nodeStateStyles: {
          hover: {
            fill: '#fff',
            shadowBlur: 30,
            shadowColor: '#ddd'
          },
          operatorhover: {
            'operator-box': {
              opacity: 1
            }
          }
        },
        modes: {
          // 画布可拖拽和放大缩小
          default: ['drag-canvas', 'zoom-canvas']
        }
      })
      graph.on('node:mouseenter', e => {
        if (e.target.get('name').includes('operator')) {
          graph.setItemState(e.item, 'operatorhover', true)
        } else {
          graph.setItemState(e.item, 'hover', true)
        }
      })
      graph.on('node:mouseleave', e => {
        graph.setItemState(e.item, 'operatorhover', false)
        graph.setItemState(e.item, 'hover', false)
      })
      const that=this;
      graph.on('node:click', e => {
        that.tupuTwoClick(e.item._cfg.id);
      })
      graph.data(relationData)
      graph.render()
      this.graphTwo=graph;
    },
    //图一点击
    tupuOneClick(idP){
      // this.$message(idP);
    },
    //图二点击
    tupuTwoClick(idP){
      const typeAndId=idP.split('-');
      if(typeAndId[0]=='e'){
        this.dialogVisibleCompany=true;
        this.getCompanyInfo(typeAndId[1]);
      }
      if(typeAndId[0]=='p'){
        this.dialogVisiblePerson=true;
        this.getPersonInfo(typeAndId[1]);
}
    },
    //图三点击
    tupuThreeClick(idP){
      const typeAndId=idP.split('-');
      if(typeAndId[0]=='e'){
        this.dialogVisibleCompany=true;
        this.getCompanyInfo(typeAndId[1]);
      }
      if(typeAndId[0]=='p'){
        this.dialogVisiblePerson=true;
        this.getPersonInfo(typeAndId[1]);
}
    },
    //绘制图3
    initGraphThree(relationData,widthP,heightP) {
      // 对数据进行处理
      relationData.children.forEach(i => {
        // 上层节点的子节点都标注位置为上（用于设置不同样式）
        if (i.label === '上') {
          i.children.forEach(j => {
            j.position = '上'
            // 上层节点的连接点的位置
            j.anchorPoints = [
              [0.5, 0],
              [0.5, 1]
            ]
          })
        } else if (i.label === '下'){
          i.children.forEach(j => {
            j.position = '下'
          })
        }
      })
      /* 中间的根节点 */
      G6.registerNode('root', {
        draw: (cfg, group) => {
          // 设置样式
          const size = [160, 22]
          const keyShape = group.addShape('rect', {
            attrs: {
              width: size[0],
              height: size[1],
              x: -size[0] / 2,
              y: -size[1] / 2,
              fill: '#3388FF',
              radius: 5
            },
            draggable: true,
            name: 'root-keyshape'
          })
          // 根节点的文本样式
          group.addShape('text', {
            attrs: {
              text: `${cfg.label}`,
              fill: '#fff',
              fontSize: 9,
              x: 0,
              y: 4,
              textAlign: 'center'
            },
            draggable: true,
            name: 'label-shape'
          })
          return keyShape
        }
      })
      /* 第二层节点（其实相当于没用，隐藏了） */
      G6.registerNode('nonode', {
        draw: (cfg, group) => {
          const size = [0, 0]
          const keyShape = group.addShape('rect', {
            attrs: {
              width: size[0],
              height: size[1],
              x: -size[0] / 2,
              y: -size[1] / 2
            },
            draggable: true,
            name: 'root-keyshape'
          })
          return keyShape
        }
      })
      // 下层节点
      G6.registerNode('downnode', {
        draw: (cfg, group) => {
          const size = [80, 40]
          const keyShape = group.addShape('rect', {
            attrs: {
              width: size[0],
              height: size[1],
              x: -size[0] / 2,
              y: -size[1] / 2,
              fill: '#fff',
              lineWidth: 1,
              stroke: '#9DBCE3',
              radius: 1
            },
            draggable: true,
            name: 'level1node-keyshape'
          })
          /* 公司 */
          group.addShape('text', {
            attrs: {
              text: `${cfg.label}`,
              fill: 'rgba(0, 0, 0, 1)',
              fontSize: 5,
              x: 0,
              y: 0,
              textAlign: 'center'
            },
            draggable: true,
            name: 'label-shape'
          })
          /* 认缴金额 */
          group.addShape('text', {
            attrs: {
              text: `${cfg.shares}万元`,
              fill: 'rgba(0, 0, 0,1)',
              fontSize: 5,
              x: 0,
              y: 10,
              textAlign: 'center'
            },
            draggable: true,
            name: 'label-money'
          })
          /* 百分比 */
          group.addShape('rect', {
            attrs: {
              x: -9,
              y: -16,
              width: 18,
              height: 6,
              radius: 3,
              fill: '#4C8CDD'
            },
            draggable: true,
            name: 'percent-box'
          })
          group.addShape('text', {
            attrs: {
              text: `${cfg.percent}%`,
              fill: '#fff',
              fontSize: 5,
              x: 0,
              y: -15,
              textAlign: 'center',
              textBaseline: 'top'
            },
            draggable: true,
            name: 'percent-shape'
          })
          // edge end
          group.addShape('circle', {
            attrs: {
              r: 2,
              x: 0,
              y: -20,
              fill: 'rgb(19, 33, 92)'
            }
          })
          return keyShape
        },
        update: undefined
      }, 'rect')
      // 上层节点
      G6.registerNode('upnode', {
        draw: (cfg, group) => {
          const size = [80, 40]
          const keyShape = group.addShape('rect', {
            attrs: {
              width: size[0],
              height: size[1],
              x: -size[0] / 2,
              y: -size[1] / 2,
              fill: '#fff',
              lineWidth: 1,
              stroke: '#9DBCE3',
              radius: 1
            },
            draggable: true,
            name: 'level1node-keyshape'
          })
          group.addShape('text', {
            attrs: {
              text: `${cfg.label}`,
              fill: 'rgba(0, 0, 0, 1)',
              fontSize: 6,
              x: 0,
              y: -6,
              textAlign: 'center'
            },
            draggable: true,
            name: 'label-shape'
          })
          group.addShape('text', {
            attrs: {
              text: `${cfg.shares}万元`,
              fill: 'rgba(0, 0, 0, 1)',
              fontSize: 6,
              x: 0,
              y: 4,
              textAlign: 'center'
            },
            draggable: true,
            name: 'sublabel-shape'
          })
          /* 百分比 */
          group.addShape('rect', {
            attrs: {
              x: -12,
              y: 8,
              width: 25,
              height: 8,
              radius: 4,
              fill: '#4C8CDD'
            },
            draggable: true,
            name: 'percent-box'
          })
          group.addShape('text', {
            attrs: {
              text: `${cfg.percent}%`,
              fill: 'rgba(255, 255, 255,1)',
              fontSize: 6,
              x: 0,
              y: 9.5,
              textAlign: 'center',
              textBaseline: 'top'
            },
            draggable: true,
            name: 'percent-shape'
          })
          // edge end
          group.addShape('circle', {
            attrs: {
              r: 2,
              x: 0,
              y: 20,
              fill: 'rgb(19, 33, 92)'
            }
          })
          return keyShape
        },
        update: undefined
      }, 'rect')
      // 配置注册节点的样式
      G6.Util.traverseTree(relationData, subtree => {
        if (subtree.level === undefined) subtree.level = 0
        if(subtree.children){
          subtree.children.forEach(child => child.level = subtree.level + 1)
        }
        if (subtree.level === 0) {
          subtree.type = 'root'
        } else if (subtree.position === '上') {
          subtree.type = 'upnode'
        } else if (subtree.position === '下') {
          subtree.type = 'downnode'
        } else {
          subtree.type = 'nonode'
        }
      })
      // 画布的大小
      const width = widthP
      const height = heightP
      // 创建g6 canvas实例
      const graph = new G6.TreeGraph({
        container: 'containerDialogThree',
        width,
        height,
        // 自适应画布
        fitView: true,
        // 树状布局
        layout: {
          type: 'compactBox',
          // ‘V’型才是上下
          direction: 'V',
          // 节点横向间距的回调函数
          getHGap: function getVGap () {
            return 10
          },
          // 子节点的宽度
          getWidth: function getWidth () {
            return 65
          },
          // 层级之间的高度间距，！！重点：设置第二层的节点与根节点的间距为0
          getVGap: function getV (d) {
            if (d.id === 'root' || d.label === '上') {
              return 0
            } else {
              return 40
            }
          },
          // 子节点的高度
          getHeight: function getHeight () {
            return 10
          }
        },
        // 点之间的连线的样式
        defaultEdge: {
          type: 'cubic-vertical',
          sourceAnchor: 0,
          targetAnchor: 1,
          style: {
            radius: 80,
            stroke: '#BBCDE4'
          }
        },
        // 默认连接的位置
        defaultNode: {
          anchorPoints: [
            // 上是根节点 下是子节点的位置
            [0.5, 1],
            [0.5, 0]
          ]
        },
        // 鼠标移入样式
        nodeStateStyles: {
          hover: {
            fill: '#fff',
            shadowBlur: 30,
            shadowColor: '#ddd'
          },
          operatorhover: {
            'operator-box': {
              opacity: 1
            }
          }
        },
        modes: {
          // 画布可拖拽和放大缩小
          default: ['drag-canvas', 'zoom-canvas']
        }
      })
      graph.on('node:mouseenter', e => {
        if (e.target.get('name').includes('operator')) {
          graph.setItemState(e.item, 'operatorhover', true)
        } else {
          graph.setItemState(e.item, 'hover', true)
        }
      })
      graph.on('node:mouseleave', e => {
        graph.setItemState(e.item, 'operatorhover', false)
        graph.setItemState(e.item, 'hover', false)
      })
      const that=this;
      graph.on('node:click', e => {
        that.tupuThreeClick(e.item._cfg.id);
      })
      graph.data(relationData)
      graph.render()
      this.graphThree=graph;
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
  border-bottom-color: #0078F2;
}
.z1_ipt{
  margin-top: 70px;
}
.tp{
  width:80%;
  margin-left:10%;
}
.tupu{
  background: url("../../../assets/img/map/sousuo.jpg");
  /* //将图片样式不重复 */
  background-repeat: no-repeat;
  /* //设置图片大小 */
  // background-size: 100%;
    position: relative;
    background-size:100% 100%;
    min-height: calc( 100vh - 120px);
}
.mr22{
  margin-right: 22px;
}
.pgs{
  /deep/.el-progress__text {
  font-size: 14px !important; /* 设置进度条文字的字体大小 */
}
}
.dl_title{
  font-size:14px;
}
.tuli{
      // position: absolute;
    //   bottom:40px;
    //   right:134px;
    }
    .linkname{
      margin-right: 20px;
      font-size: 14px;
      line-height: 30px;
    }
    .qianglian{
      display: inline-block;
      width:10px;
      height: 10px;
      border-radius: 10px;
      background-color: #489adc;
      //background-color: #b5d936;
    }
    .ruolian{
      display: inline-block;
      width:10px;
      height: 10px;
      border-radius: 10px;
      background-color: #05d5e3;
      //background-color: #8faeda;
    }
    .touzi{
      display: inline-block;
      width:10px;
      height: 5px;
      border-top:2px solid #489adc;
    }
    .renzhi{
      display: inline-block;
      width:10px;
      height: 5px;
      border-top:2px solid #05d5e3;
    }
</style>
<style>
.DVSL-bar-horizontal{
  display: none;
}
.DVSL-bar-vertical{
  display: none;
}
</style>
