<template>
<div class="baogao" style="padding-top:30px;">
  <!-- <FangziHeadTwo ttPass="评价报告"></FangziHeadTwo> -->
  <!-- <div style="position:absolute;top:30px;right:60px;z-index: 100;">
        <div class="huaxiangzones"> 
          <el-radio-group v-model="activeTab"   @input="tabChange" size="mini">
      <el-radio-button label="0">企业画像</el-radio-button>
      <el-radio-button label="1">关联图谱</el-radio-button>
      <el-radio-button label="2">评价报告</el-radio-button>
      <el-radio-button label="3">企业管理</el-radio-button>
    </el-radio-group>
        </div>
   
      </div> -->
  <div style="overflow: hidden;">
  </div>
    <div   style="text-align: center">
      <div style="line-height: 80px;font-weight: 550;font-size:20px;color:#2E2E2E;">产业企业画像</div>
      <el-autocomplete
 style="width:640px"
v-model.trim="state"
:fetch-suggestions="querySearch"
placeholder="请输入企业名称关键字"
@select="handleSelect"
></el-autocomplete>
<el-button @click="btnPreview" :disabled="isLoading" style="margin-left:10px;">预览</el-button>
<el-button @click="btnSure" type="primary" :disabled="isLoading2">一键评价</el-button>
    </div>
    <div style="margin:50px 105px 0px;">
      <el-tabs v-model="activeName"  style="background: #fff;height: calc( 100vh - 355px)" type="card" @tab-click="handleClick">
        <el-tab-pane label="企业全景报告" name="1">
          <div v-loading="wordLoading" style="height: calc( 100vh - 420px); overflow: auto;">
            <div v-html="vHtml"  ></div>
            <div v-show="vHtml==''"> 
              <el-empty :image-size="300"></el-empty>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业综合能力报告" name="2">
          <div v-loading="wordLoading" style="height: calc( 100vh - 420px); overflow: auto;">
            <div v-html="vHtml2"  ></div>
            <div v-show="vHtml2==''"> 
              <el-empty :image-size="300"></el-empty>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
</div>
</template>
<script>
import companytab from '../../../components/map/companytab'
import mammoth from 'mammoth'
// import {enterpriseSearchName,downloadReport} from '../../../api/map/common'
import {enterpriseSearchName,downloadReport} from "../../../request/moudle/map/common"


import FangziHeadTwo from "../../../components/zkwl/FangziHeadTwo.vue";
export default {
  name: "baogao",
  data(){
    return  {
      keyword:'',
      activeName:'1',
      vHtml:'',
      vHtml2:'',
      wordLoading:false,
      isRequest:false,//
        //
        state: '',
      timeout: null,
      suggestions: [],
      nowId:null,
      nowName:'',
      isLoading:false,
      isLoading2:false,
      activeTab:2,
    }
  },
  watch: {
    activeName: {
        handler(newVal, oldVal) {
          if(this.nowId==null){
            return;
          }
          if(newVal==1){
            this.getWordOne();
          }else{
            this.getWordTwo();
          }
        },
        // deep: true,
        // immediate: true
    },
  },
  created() {
    // this.$store.commit("CHANGE_TABTYPE", 2);
  },
  mounted(){

  },
  components:{
    companytab,
    FangziHeadTwo,
  },
  methods:{
    tabChange(val){
     

     sessionStorage.removeItem("parampass_huaxiang");
       sessionStorage.setItem(
         "parampass_huaxiang",
         JSON.stringify({ type: val })
       );
       this.$router.push({
       path: '/map/huaxiang',
     });
     this.$bus.$emit("refresh_Huaxiang");
   },
    handleClick(){
      // if(this.activeName=='2'){
      //   this.getWordTwo();
      // }
    },
    querySearch(queryString, cb) {
      const that=this;
      // 清除定时器
      clearTimeout(this.timeout);
      if(that.state==''){
        return;
      }
      this.timeout = setTimeout(() => {
        // 模拟从后台获取数据的异步操作
        enterpriseSearchName({key:queryString}).then(res=>{
          this.entsData=res.data;
          const names=res.data.map(item=>{
            return {value:item.name,id:item.id} ;
          })
            that.suggestions = names;
      //       // 调用 callback 返回建议列表的数据
            cb(that.suggestions);
        }).catch(err=>{
          cb([]);
        });
      }, 500); // 延迟200毫秒请求，防止密集的请求
      // this.timeout = setTimeout(() => {
      //   // 模拟从后台获取数据的异步操作
      //   axios.get('your-api-url', { params: { q: queryString } })
      //     .then(response => {
      //       // 假设返回的数据格式为 { data: ['Option 1', 'Option 2', ...] }
      //       this.suggestions = response.data.data;
      //       // 调用 callback 返回建议列表的数据
      //       cb(this.suggestions);
      //     })
      //     .catch(error => {
      //       console.error(error);
      //       cb([]);
      //     });
      // }, 200); // 延迟200毫秒请求，防止密集的请求
    },
    handleSelect(item) {
      console.log('选中项',item);
      // 选中某项时的处理逻辑
      // this.$message.error(item.id);
      this.nowId=item.id;
      this.nowName=item.value;
      // if(this.activeName=='1'){
      //   this.getWordOne();
      // }else{
      //   this.getWordTwo();
      // }
    },
    btnPreview(){
      if(this.nowId==null){
        this.$message.error('请先选择公司');
        return;
      }
      this.getWordOne();
      if(this.activeName=='1'){
        this.getWordOne();
      }else{
        this.getWordTwo();
      }
    },
    getWordOne(){
      if(this.isRequest){
        return;
      }
      this.isRequest=true;
      this.vHtml='';
      this.wordLoading=true;
     let that=this;
     downloadReport({id:this.nowId,reportType:this.activeName}).then((res) => {
            const blob = new Blob([res.data], {
					type: "application/octet-stream"
				})
				var reader = new FileReader()
				reader.readAsArrayBuffer(blob)
				reader.onload = function(e) {
					const buffer = e.target.result // 此时是arraybuffer类型
					mammoth.convertToHtml({ arrayBuffer: buffer }).then((result) => {
            that.wordLoading=false;
						that.vHtml = result.value
            that.isRequest=false;
					}).done()
				}
            if (res) {
            } else {
              // that.$notify.error({ title: "失败", message: "接口请求失败" });
              // that.loading = false;
            }
          }).catch(function (error) {
            // that.$notify.error({ title: "失败", message: "接口请求失败" });
            // that.loading = false;
            that.wordLoading=false;
            that.isRequest=false;
          });
    },
    getWordTwo(){
      if(this.isRequest){
        return;
      }
      this.isRequest=true;
      this.vHtml2='';
      this.wordLoading=true;
     let that=this;
     downloadReport({id:this.nowId,reportType:2}).then((res) => {
            const blob = new Blob([res.data], {
					type: "application/octet-stream"
				})
				var reader = new FileReader()
				reader.readAsArrayBuffer(blob)
				reader.onload = function(e) {
					const buffer = e.target.result // 此时是arraybuffer类型
					mammoth.convertToHtml({ arrayBuffer: buffer }).then((result) => {
            that.wordLoading=false;
						that.vHtml2 = result.value
            that.isRequest=false;
					}).done()
				}
            if (res) {
            } else {
              // that.$notify.error({ title: "失败", message: "接口请求失败" });
              // that.loading = false;
            }
          }).catch(function (error) {
            // that.$notify.error({ title: "失败", message: "接口请求失败" });
            // that.loading = false;
            that.wordLoading=false;
            that.isRequest=false;
          });
    },
    getYMDHmS(){
      const now = new Date();
const year = now.getFullYear(); // 年份，例如：2021
let month = now.getMonth() + 1; // 月份，范围是0-11，需要+1，例如：1月为1
if(month<10){
  month='0'+month;
}
let day = now.getDate(); // 日期，例如：31
if(day<10){
  day='0'+day;
}
// const hours = now.getHours(); // 小时，24小时制，例如：23
// const minutes = now.getMinutes(); // 分钟，例如：59
// const seconds = now.getSeconds(); // 秒，例如：30
// return `${year}${month}${day}${hours}${minutes}${seconds}`
return `${year}${month}${day}`
    },
    btnSure(){
      if(this.nowId==null){
        this.$message.error('请先选择公司');
        return;
      }
      this.isLoading2=true;
      downloadReport({id:this.nowId,reportType:this.activeName}).then((res) => {
// 创建一个 blob 对象
const blob = new Blob([res.data]);
    // 创建一个指向 blob 的 URL
    const url = window.URL.createObjectURL(blob);
    // 创建一个 <a> 元素
    const link = document.createElement('a');
    // 设置 <a> 元素的属性
    link.href = url;
    link.download = (this.activeName==1?'企业全景报告':'企业综合能力报告')+'-'+this.getYMDHmS()+'-'+this.nowName+'.docx'; // 设置下载文件的名称
    // 添加到 DOM 中
    document.body.appendChild(link);
    // 触发点击事件
    link.click();
    // 释放 URL 对象
    window.URL.revokeObjectURL(url);
    // 移除 <a> 元素
    document.body.removeChild(link);
    this.isLoading2=false;
      }).catch(err=>{
        this.isLoading2=false;
      })
    },
  }
}
</script>
<style lang="scss" scoped>
  .baogao{
    background: url("../../../assets/img/map/sousuo.jpg");
  /* //将图片样式不重复 */
  background-repeat: no-repeat;
  /* //设置图片大小 */
  // background-size: 100%;
    position: relative;
    background-size:100% 100%;
    min-height: calc( 100vh - 115px);
  }
  .huaxiangzones{
  /deep/.el-radio-button__inner{
    width:100px;
  }
}
</style>
