<template>
  <div class="zhaobiao" style="padding-top:60px;">
      <!-- <gxjyheader title="精准获客"  desc=" "></gxjyheader> -->
    <div class="" style="margin:0px 105px 0px;">
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item >精准获客</el-breadcrumb-item>
      </el-breadcrumb> -->
      <div >
        <el-form ref="form" :model="form" style="background: #fff;padding:10px 0" label-width="120px" fontSize="16px">
          <div style="position: relative;"> 
            <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left:20px;">
            <el-tab-pane label="企业名称" name="name"></el-tab-pane>
    <el-tab-pane label="查产品" name="prod"></el-tab-pane>
    <el-tab-pane label="联系电话" name="phone"></el-tab-pane>
    <el-tab-pane label="联系人姓名" name="person"></el-tab-pane>
    <el-tab-pane label="司法风险" name="legal"></el-tab-pane>
    <el-tab-pane label="知识产权" name="patent"></el-tab-pane>
  </el-tabs>
  <div style="position:absolute;top:0;right:0;"> 

  </div>
          </div>
    
     <div> 
      <avue-search v-show="activeName=='legal'" style=""  :option="optionLegal"   
                   v-model="formLegal" >
  
      </avue-search>
      <avue-search v-show="activeName=='patent'" style=""  :option="optionPatent"   
                   v-model="formPatent" >
  
      </avue-search>
     </div>
          <el-row>
            <el-col :span="24" >
              <el-form-item style="height:39px;"  :label="nowName+':'" v-if="activeName!='legal'&&activeName!='patent'&&activeName!='name'" > 
                <el-input  placeholder="请输入"   style="width: 438px;" v-model="form.keyword" clearable></el-input>
              </el-form-item>
              <el-form-item style="height:39px;width:100%;" label="企业名称" v-if="activeName=='name'">
                 <el-autocomplete
        style="width:438px"
    v-model.trim="state"
    :fetch-suggestions="querySearch"
    placeholder="请输入企业名称关键字"
    @select="handleSelect"
    clearable

  >

</el-autocomplete>
                </el-form-item>
              <!-- <el-form-item label="" >
                <el-input placeholder="请输入"   style="width: 438px;margin-left: -100px;" v-model="form.keyword">
                  <el-select v-model="searchType" slot="prepend" placeholder="请选择" style="width:110px;">
      <el-option :label="item.dictValue" :value="item.dictKey" v-for="item in tiaojianList" :key="item"></el-option>

    </el-select>
                </el-input>
              </el-form-item> -->
            </el-col>
           
      
          </el-row>
          <el-row>
            <el-col :span="8">
              <!-- <el-form-item label="采购类型">
                <el-select v-model="form.typeList" placeholder="请选择">
                  <el-option
                      v-for="item in getTypeList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item> -->
            </el-col>
            <el-col :span="8">
              <!-- <el-form-item label="行业">
                <el-select v-model="form.industryList" placeholder="请选择">
                  <el-option
                      v-for="item in getIndustryList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item> -->
            </el-col>
          </el-row>
          <div style="position:relative;"> 
            <avue-search :option="option"   
                   v-model="form2" >
        <template #title="{}">
        
        </template>
      </avue-search>
      <div style="position: absolute;top:4px;left:460px;"> 
          <span style="cursor: pointer;color: rgba(0, 0, 0, .65);">
            <el-popover
  placement="bottom"
  width="300"
  v-model="visibleZone">
  <el-cascader
  v-model="form.countyTb"
    :options="optionsZone"
    :props="propsZone"
    collapse-tags
    clearable></el-cascader>
  <div style="text-align: right; margin: 0">
    <el-button type="primary" size="mini" @click="visibleZone = false">确定</el-button>
  </div>
  <el-button slot="reference" style="border:0px;">更多</el-button>
</el-popover></span>
      </div>
      <div style="position: absolute;top:58px;left:960px;"> 
        <span style="cursor: pointer;color: rgba(0, 0, 0, .65);">
            <el-popover
  placement="bottom"
  width="300"
  v-model="visibleHangye">
  <el-cascader
  v-model="form.hangyeTb"
    :options="optionsHangye"
    :props="propsHangye"
    collapse-tags
    clearable></el-cascader>
  <div style="text-align: right; margin: 0">
    <el-button type="primary" size="mini" @click="visibleHangye = false">确定</el-button>
  </div>
  <el-button slot="reference" style="border:0px;">更多</el-button>
</el-popover></span>
      </div>
          </div>
          <el-form-item>
            <el-button @click="reset" size="small" style="float:right;">重置</el-button>
            <el-button type="primary" size="small" @click="loadlist(true)" style="float:right;margin-right:20px;">筛选</el-button>

          </el-form-item>
        </el-form>
      </div>
      <div style="font-size:16px;color:#5C5C5C;">共有 {{page.total}} 条搜索结果</div>
      <div class="lists">

        <!-- <div class="list flex" v-for="(item,index) in items" @click="gotoxq(item)" :key="index">
          <div class="flex-1">
            <div class="title"><span class="biao">【招标{{item.categoryName}}】</span>{{item.title}}</div>
            <div class="time"><span class="st1" :class="{red:item.state=='进行中'}">{{item.state!='进行中'?item.state:'剩余'+item.days+'天'}}</span></div>
            <div class="address">{{item.provinceName}}{{item.cityName}}{{item.areaName}}<span class="line"></span>{{item.industryName}}
            <span  style="float: right">{{item.createTime}}</span>
            </div>
          </div>
        </div> -->
        <el-row style="background-color: white;text-align: center;color:#333333;font-size:14px;">
  <el-col :span="7"><div class="">企业信息</div></el-col>
  <el-col :span="2"><div class="">法人代表</div></el-col>
  <el-col :span="3"><div class="">所属行业</div></el-col>
  <el-col :span="5"><div class="">产品和服务</div></el-col>
  <el-col :span="3"><div class="">联系电话</div></el-col>
  <el-col :span="4"><div class="">平台认证时间</div></el-col>
</el-row>
<div v-for="(item,index) in items " :key="index" style="height:140px;margin:10px 0;background-color: white;text-align: center;color:#333333;"> 
  <el-row >
  <el-col :span="7"><div class="grid-content bg-purple" style="text-align: left;margin-left:25px;">
    <div v-html="item.name" :title="item.name.replace(/<[^>]*>/g, '')" class="ellipsis" style="color:#333333;font-size:18px;font-weight: bold;line-height: 26px;margin-top:20px;"> 

  </div>
  <div style="margin-top:4px;height:20px;" class="ellipsis"> 
    <span v-if="item.labels!=''"> 
      <span v-for="item2 in item.labels.split(',')" :key="item2"> 
        <el-tooltip :content="item.labels" placement="bottom" effect="light">
      <el-tag class="ellipsis" style="margin-right:10px;max-width:130px;" size="mini" >{{item2}}</el-tag>
    </el-tooltip>
      </span>

    </span>
  </div>
  <div class="ellipsis" :title="item.address" style="margin-top:10px;"> 
    地址：{{item.address}}
  </div>
  </div>
</el-col>
  <el-col :span="2"><div class="ellipsis" :title="item.legalPerson" style="line-height: 140px;">{{item.legalPerson==''?'&nbsp':item.legalPerson}}</div></el-col>
  <el-col :span="3"><div class="ellipsis" :title="item.industry" style="line-height: 140px;">{{item.industry==''?'&nbsp':item.industry}}</div></el-col>
  <el-col :span="5"><div class="ellipsis" :title="item.businessScope" style="line-height: 140px;">{{item.businessScope==''?'&nbsp':item.businessScope}}</div></el-col>
  <el-col :span="3"><div class="ellipsis" :title="item.phone" style="line-height: 140px;">{{item.phone==''?'&nbsp':item.phone}}</div></el-col>
  <el-col :span="4"><div class="" style="line-height: 140px;">{{item.regTime==''?'&nbsp':item.regTime}}</div></el-col>
</el-row>
</div>

      </div>
      <div class="fenye" style="text-align: center;margin-bottom: 30px;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
      <el-dialog title="发送" width="600px" append-to-body :visible.sync="dialogTableVisible">
        <el-form ref="form" :model="shen" label-width="120px" :rules="rules">
          <el-form-item label="咨询详情" prop="detail">
            <el-input rows="5"  style="width: 300px" type="textarea" v-model="shen.detail"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input   v-model="shen.contacts"></el-input>
          </el-form-item>
          <el-form-item label="手机号"   prop="phone">
            <el-input  v-model.number="shen.phone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="margin-top: 20px" @click="submitForm()">保存</el-button>
            <el-button @click="dialogTableVisible=!dialogTableVisible">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import gxjyheader from './gxjyheader'
import {getIndustryList, getTypeList, submitconsult, tenderinglist} from "../../../request/moudle/demand";
import {enterprisePageList,enterpriseSearchName} from "../../../request/moudle/map/common"
export default {
  name: "zhaobiao",
  data(){
    return {
       //
       state: '',
      timeout: null,
      suggestions: [],
      isLoading:false,
      //
      form:{
        keyword:''
      },
      form2:{},
      formLegal:{},
      formPatent:{},
      shen:{},
      zxid:'',
      rules: {
        detail: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },{ type: 'number', message: '手机号必须为数字值'}
        ]
      },
      dialogTableVisible:false,
      getIndustryList:[],
      getTypeList:[],
      props: {
        label: 'name',
        value: 'code'
      },
      page:{
        current:1,
        size:10,
        total:0
      },
      items:[],
      option: {
        column: [
        {
            label: '企业所在地',
            dicData:[],
            prop: 'provinceId',
            multiple: true,
            props: {
              label: 'title',
              value: 'id'
            },
          },
        {
            label: '行业',
            dicData:[],
            prop: 'industryList',
            multiple: true,
            props: {
              label: 'title',
              value: 'id'
            },
          },
           {
            
          label: '企业类型',
          prop: 'typeList',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: true,
          dicData:[],
        },
       
       ]
      },
      optionLegal: {
        column: [
      
           {
            
          label: '司法风险',
          prop: 'legalList',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: true,
          dicData:[],
        },
       
       ]
      },
      optionPatent: {
        column: [
      
           {
            
          label: '知识产权',
          prop: 'patentList',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: true,
          dicData:[],
        },
       
       ]
      },
      searchType: '',
      tiaojianDt:[],
      activeName: 'name',
      nowName:'企业名称',
      legalObj:{},
      patentObj:{},
      //
      propsZone: { multiple: true },
        optionsZone: [],
        visibleZone:false,
        propsHangye: { multiple: true },
        optionsHangye: [],
        visibleHangye:false,
    }
  },
  created() {
    this.loadconfig()
    this.loadlist()
  },
  methods:{
    handleSelect(item) {
      // 选中某项时的处理逻辑
    },
    querySearch(queryString, cb) {
      const that=this;
      // 清除定时器
      clearTimeout(this.timeout);
      if(that.state==''){
        return;
      }
      this.timeout = setTimeout(() => {
        // 模拟从后台获取数据的异步操作
        enterpriseSearchName({key:queryString}).then(res=>{
      
          const names=res.data.map(item=>{
            return {value:item.name,id:item.id} ;
          })
            that.suggestions = names;
      //       // 调用 callback 返回建议列表的数据
            cb(that.suggestions);
        }).catch(err=>{
          cb([]);
        });
      }, 500); // 延迟200毫秒请求，防止密集的请求
      // this.timeout = setTimeout(() => {
      //   // 模拟从后台获取数据的异步操作
      //   axios.get('your-api-url', { params: { q: queryString } })
      //     .then(response => {
      //       // 假设返回的数据格式为 { data: ['Option 1', 'Option 2', ...] }
      //       this.suggestions = response.data.data;
      //       // 调用 callback 返回建议列表的数据
      //       cb(this.suggestions);
      //     })
      //     .catch(error => {
      //       console.error(error);
      //       cb([]);
      //     });
      // }, 200); // 延迟200毫秒请求，防止密集的请求
    },
    handleClick(tab, event) {
      let that=this;
      setTimeout(function(){

        const act=that.activeName;

        if(act=='legal'||act=='patent'){
          that.form.keyword='';
    
          //多选
          // if(act=='legal'){
          //   if(that.option.column.length==3){
          //     that.option.column.unshift(that.legalObj)
          //   }else if(that.option.column.length==4){
          //     that.option.column.shift();
          //     that.option.column.unshift( that.legalObj)
          //   }
        
          // }
          // if(act=='patent'){
          //   if(that.option.column.length==3){
          //     that.option.column.unshift(that.patentObj)
          //   }else if(that.option.column.length==4){
          //     that.option.column.shift();
          //     that.option.column.unshift(that.patentObj)
          //   }
          // }
        }else{
        
          //单选
          const zidian={
            // 'name':'企业名称',
            'prod':'查产品',
            'phone':'联系电话',
            'person':'联系人姓名'
          }
          that.nowName=zidian[act];
          if(act!='name'){
            // that.form.keyword='';
            // that.$message.error(act);
            that.form.keyword='';
          }else{
            that.state='';
            that.form.keyword='';
          }
        }
      },0)
        console.log(tab, event);
      },
    submitForm(){
      this.shen.tenderingBiddingId=this.zxid
      this.$api.demand.submitconsult(this.shen).then((res)=>{
        this.dialogTableVisible=false
        this.$message({
          type: "success",
          message: "提交成功!"
        });
      })
    },
    zixun(item){
      this.shen={}
      this.zxid=item.id
      this.dialogTableVisible=true
    },
    gotoxq(item){
      this.$router.push({
        path:'/allzhaobiaoxq',
        query:{
          id:item.id,
        }
      })
    },
    loadconfig(){

      
      this.$api.zcfw.dictList({code:'ENT_SEARCH_HOT_INDUSTRY'}).then((res)=>{

     const industryList=this.findObject(this.option.column, 'industryList')
     industryList.dicData=res.data[0].children.map(item=>{
          return {id:item.dictKey,title:item.dictValue}
        })
        industryList.dicData.unshift({ title: '不限', id: '' })
   
      })

      this.$api.zcfw.dictList({code:'ENT_SEARCH_LEGAL'}).then((res)=>{

     const legalList=this.findObject(this.optionLegal.column, 'legalList')
     legalList.dicData=res.data[0].children.map(item=>{
          return {id:item.dictKey,title:item.dictValue}
        })
        legalList.dicData.unshift({ title: '不限', id: '' })
 
      })
      this.$api.zcfw.dictList({code:'ENT_SEARCH_PATENT'}).then((res)=>{
        const patentList=this.findObject(this.optionPatent.column, 'patentList')
        patentList.dicData=res.data[0].children.map(item=>{
          return {id:item.dictKey,title:item.dictValue}
        })
        patentList.dicData.unshift({ title: '不限', id: '' })

      })
      ///////////////////////////////////////////////////////

      this.$api.zcfw.dictList({code:'ENT_SEARCH_HOT_AREA'}).then((res)=>{

        const provinceId=this.findObject(this.option.column, 'provinceId')
        provinceId.dicData=res.data[0].children.map(item=>{
          return {id:item.dictKey,title:item.dictValue}
        })
        provinceId.dicData.unshift({ title: '不限', id: '' })
        console.log('地区',provinceId);
      })
         this.$api.zcfw.dictList({code:'ENT_SEARCH_TYPE'}).then((res)=>{

        const typeList=this.findObject(this.option.column, 'typeList')
        typeList.dicData=res.data[0].children.map(item=>{
          return {id:item.dictKey,title:item.dictValue}
        })
        typeList.dicData.unshift({ title: '不限', id: '' })
      })
      this.$api.zcfw.dictList({code:'ENT_SEARCH_CONDITION'}).then((res)=>{
        this.tiaojianDt=res.data[0].children;
        this.tiaojianList=res.data[0].children;
        this.searchType=res.data[0].children[0].dictKey;
      }).catch()
   
      this.$api.zcfw.addressTree().then((res)=>{
        this.optionsZone=res.data;
     
      }).catch()
      this.$api.zcfw.industryTree().then((res)=>{
        this.optionsHangye=res.data;

      }).catch()

       
    },
    loadlist(bol){
  
      if(bol){
        this.page.current=1
      }
      // if(this.form.cascader){
      //   this.form.province=this.form.cascader[0]
      //   this.form.city=this.form.cascader[1]
      //   this.form.area=this.form.cascader[2]
      // }
      let provinceP=[];
      if(this.form2.provinceId!=undefined){
        provinceP=this.form2.provinceId;
      }
      let provinceList=provinceP;

      let countyS=[];
      if(this.form.countyTb!=undefined){
        countyS=this.form.countyTb.map(item=>{
          return item[item.length-1]
        })
      }
      const canshu={
          cityList:[],
          countyList:countyS,
          provinceList:provinceList,
          searchType:this.activeName,

        };
        // console.log('行业树',this.form.hangyeTb);
        let hangyeDb3=[]
        if(this.form.hangyeTb!=undefined){
          hangyeDb3=this.form.hangyeTb.map(item=>{
            return item[item.length-1]
          })
        }
        let industryListP=[];
        if(this.form2.industryList!=undefined){
          industryListP=this.form2.industryList;
        }
        const hangyes=industryListP.concat(hangyeDb3);
        // console.log('所选行业',hangyes);
        // console.log('表单1',this.form);
      let obj={
        ...this.form,...this.form2,...this.formLegal,...this.formPatent,...canshu,...{industryList:hangyes}
      }
      //联想
     
      if(this.activeName=='name'){
        obj.keyword=this.state;
      }else{
  
        obj.keyword=this.form.keyword;
      }
      console.log('参数',obj);
      obj.current=this.page.current
      obj.size=this.page.size
      //
      //参数,所有的数组都要过滤掉空字符串
      obj.cityList=obj.cityList.filter(item=>{
        return item!="";
      })
      obj.countyList=obj.countyList.filter(item=>{
        return item!="";
      })
      obj.industryList=obj.industryList.filter(item=>{
        return item!="";
      })
      let legalP=[]
      if(obj.legalList!=undefined){
        legalP=obj.legalList.filter(item=>{
        return item!="";
      })
      }
      obj.legalList= legalP
      let patentP=[]
      if(obj.patentList!=undefined){
        patentP=obj.patentList.filter(item=>{
        return item!="";
      })
      }
      obj.patentList=patentP
      obj.provinceList=obj.provinceList.filter(item=>{
        return item!="";
      })
      let typeP=[]
      if(obj.typeList!=undefined){
        typeP=obj.typeList.filter(item=>{
        return item!="";
      })
      }
      obj.typeList=typeP
      // obj.cityList=obj.cityList.filter(item=>{
      //   return item!="";
      // })
      // return
        this.$api.zcfw.entOnlineList(obj).then((res)=>{
       
        this.items=res.data.records
        this.page.total=res.data.total
      })
      // this.$api.index.biddinglist(obj).then((res)=>{
      //   this.items=res.data.records
      //   this.page.total=res.data.total
      // })
    },
    reset(){
      this.form={}
      for (const val of this.option.column){
        if(val.multiple){
          this.form2[val.prop]=[]
        }else {
          this.form2[val.prop]=''
        }
      }
      //
      for (const val of this.optionLegal.column){
        if(val.multiple){
          this.formLegal[val.prop]=[]
        }else {
          this.formLegal[val.prop]=''
        }
      }
      //
      for (const val of this.optionPatent.column){
        if(val.multiple){
          this.formPatent[val.prop]=[]
        }else {
          this.formPatent[val.prop]=''
        }
      }
      //
      for (const val of this.option.column){
        if(val.multiple){
          this.form2[val.prop]=[]
        }else {
          this.form2[val.prop]=''
        }
      }
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    lazyLoad(node, resolve) {
      let stop_level = 2;
      let level = node.level;
      let data = node.data || {}
      let code = data.code;
      let list = [];
      let callback = () => {
        resolve((list || []).map(ele => {
          return Object.assign(ele, {
            leaf: level >= stop_level
          })
        }));
      }
      if (level == 0) {
        this.$api.index.lazylist({parentCode:'00'}).then(res => {
          list = res.data;
          callback()
        });
      } else if (level == 1) {
        this.$api.index.lazylist({parentCode:code}).then(res => {
          list = res.data;
          callback()
        });
      } else if (level == 2) {
        this.$api.index.lazylist({parentCode:code}).then(res => {
          list = res.data;
          callback()
        });
      } else {
        callback()
      }
    }
  },
  components:{
    // gxjyheader
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-input{
  width: 100%;
}

.zhaobiao{
  background: #F5F5F5;
  ::v-deep  .el-form-item__label {
  font-size: 14px; /* 设置为你想要的字体大小 */
}
}
  .content{

    width: 1200px;
    margin: 15px auto;
    .sxx{
      background:#FFFFFF;
      padding:30px  40px;
      margin-top: 25px;
    }
    .fenye{
      text-align: center;
      padding: 30px 0;
      background: #fff;
    }
    .lists{
      // background: #fff;
      //padding: 15px 30px;
      font-size: 14px;
      color: #292929;
      line-height: 30px;
      .list{
        border-top: 1px solid #D1D1D1;
        padding: 15px 0;
      }
      .title{
        font-size: 16px;
        font-weight: 500;
        color: #000000;
      }
      .time{
        text-align: right;
      }
      .red{
        color: red;
      }
      .biao{
        display: inline-block;
        color: #0078F2;
        text-align: center;
        line-height: 24px;
        margin: 5px;
      }
      .line{
        border-left: 1px solid #292929;
        margin: 0 15px;
      }
      .button{
        text-align: center;
        margin: 15px 30px;
        margin-top: 30px;
      }
    }
  }


  .ellipsis {
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  overflow: hidden;

  /* 隐藏溢出的内容 */
  text-overflow: ellipsis;
  /* 使用省略号表示溢出的文本 */
}
</style>