<template>
    <div>
      <div style="padding-top:15px;"> 
        <el-breadcrumb  class="mianbao"  separator="/">
      <!-- <el-breadcrumb-item>产业链地图</el-breadcrumb-item> -->
      <el-image
                    style="width:24px;height:25px;float:left;margin-top:-8px;margin-left:14px;padding-right:10px;margin-bottom:0;padding-bottom:0;"
                    :src="require( `@/assets/img/map/wuzi.png`)"
                    :fit="'scale-down'"
                  ></el-image>
      <el-breadcrumb-item><a href="#/map/xianzhuang"><span class="mianbao2">{{ttPass}}</span></a></el-breadcrumb-item>
    </el-breadcrumb>
      </div>
     
    </div>
  </template>
  
  <script>

  
  export default {
    name: 'FangziHead',
    props: {
      // percentagePass: {
      //   type: Number,
      //   default: 0
      // },
      ttPass: {
        type: String,
        default: '产业链地图'
      },
      // showtextPass: {
      //   type: Boolean,
      //   default: true
      // },
    },
    data() {
      return {
   
      }
    },
    watch: {
    
    },
    methods: {
        // setItemText(row,row2) {
        //         return () => {
        //             // return row
                  
        //            return row;
        //           //  return 99;
        //         }
        //     },
    }
  }
  </script>
  
  <style lang="scss" scoped>
// /deep/.el-progress__text{
//   font-size:14px !important;
//   font-weight: bolder;
// }
  </style>
  