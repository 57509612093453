<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">商机洞察</div>
      <div class="h2">数据线索挖掘、多维度精准客户、助企精准获客</div>
    </div>
   <div> 
    <div class="baogao">
    
    <div style="position:absolute;top:10px;right:110px;z-index: 100;">
      <div class="huaxiangzones"> 
        <el-radio-group v-model="activeTab"   @input="tabChange" size="mini">
    <el-radio-button label="0">企业画像</el-radio-button>
    <el-radio-button label="1">关联图谱</el-radio-button>
    <el-radio-button label="2">评价报告</el-radio-button>
    <el-radio-button label="3">精准获客</el-radio-button>
    <el-radio-button label="4">企业管理</el-radio-button>
  </el-radio-group>
      </div>
 
    </div>
  <div>
    <FaceCom v-if="activeTab==0"></FaceCom>
    <GltupuCom v-if="activeTab==1" :typeson="typeson"></GltupuCom>
    <BaogaoCom v-if="activeTab==2"></BaogaoCom>
    <AllhuokeCom v-if="activeTab==3"></AllhuokeCom>
    <EntmanageCom v-if="activeTab==4"></EntmanageCom>
  </div>
</div>
   </div>

 


  </div>

</template>

<script>
import {policyindex, policypage} from "../../request/moudle/zcfw";
import {parkindex, parklist} from "../../request/moudle/park";
import config from "../../utils/config";
import FaceCom from './components/face.vue'
  import GltupuCom from './components/gltupu.vue'
  import BaogaoCom from './components/baogao.vue'
  import EntmanageCom from './components/entmanage.vue'
  import AllhuokeCom from './components/allhuoke.vue'
export default {
  name: "zchj",
  data(){
    return {
      number:0,
      page:{
        current:1,
        size:10,
        total:0
      },
      parkList:[],
      industryList:[],
      hotQA:[],
      datas:'',
      arr:[{name:'政策长期稳定',src:require('../../assets/park/img.png')},
        {name:'营商环境优质',src:require('../../assets/park/img_1.png')},
        {name:'专享行业扶持',src:require('../../assets/park/img_2.png')},
        {name:'区域优势明显',src:require('../../assets/park/img_3.png')},
        {name:'高新/上市补贴',src:require('../../assets/park/img_4.png')},
        {name:'产业集聚优势',src:require('../../assets/park/img_5.png')},
        {name:'联动发展优势',src:require('../../assets/park/img_6.png')},
        {name:'专业化服务优势',src:require('../../assets/park/img_7.png')}
      ],
      //
      activeTab:0,
        ss_huaxiang:null,
        typeson:'first',
    }
  },
  created() {
    this.loadconfig()
    const p=this.$route.query;
    if(p.type==undefined){

    }else{
      if(p.typeson!=undefined){
        this.typeson=p.typeson;
      }
      this.activeTab=p.type;
      
    }
      console.log('参数',p);
  },
  mounted(){
      this.initPage();
      // this.$bus.$on("refresh_Huaxiang", (val) => {
	    //   console.log(val)   // 0.7022180283884656
      //   // this.$message.error('跳转');
      //   this.initPage();
	    // });
    },
    components:{
      FaceCom,
      GltupuCom,
      BaogaoCom,
      EntmanageCom,
      AllhuokeCom,
    },
  methods:{
    loadconfig(){
      this.$api.park.parkindex().then((res)=>{
        this.datas=res.data
        this.parkList=res.data.parkList
        this.industryList=res.data.industryList
        this.hotQA=res.data.hotQA
      })
    },
    choosecheck(index){
      this.number=index
    },
    go(num){
      if(num==1){
        this.$router.push({
          path:'/yqgl',
        })
      }else if(num==2){
        this.$router.push({
          path:'/parkzchj',
        })
      }else{
        window.open(config.adminhref+'#/park/index','_blank')
      }
    },
    gomore(){
      this.$router.push({
        path:'/yqgl',
      })
    },
    goxq(item){
      this.$router.push({
        path:'/yqglxq',
        query:{
          id:item.id,
        }
      })
    },
    initPage(){
  //       const obj= sessionStorage.getItem('parampass_huaxiang')
  //  if(obj==undefined){
  //  }else{
  //   this.ss_huaxiang=JSON.parse(obj);
  //  }
  //  console.log('参数',this.ss_huaxiang);
  //  if(this.ss_huaxiang!=null){
  //   this.activeTab=this.ss_huaxiang.type;
  //  }
      // this.$store.commit("CHANGE_TABTYPE", 2);
      // const type=this.$route.query.type;
      // if(type!=undefined){
      //   this.activeTab=type;
      // }
      },
      tabChange(){
        // sessionStorage.removeItem('parampass_huaxiang');
        // this.ss_huaxiang=null;
        // if(this.activeTab==2){
        //   this.$router.push({ path: "/map/baogao" });
        // }
      }
  }
}
</script>

<style lang="scss" scoped>
.yuan{
  width: 1200px;
  margin: 0 auto;
  background: url("../../assets/park/yuan.png") no-repeat;
  background-size: 1200px 593px;
}
.header{
  height: 220px;
  background: url("../../assets/banner/yqgl.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.glleft{
  overflow: hidden;
  width: 280px;
  height: 448px;
  background: url("../../assets/park/bg5.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  .mgr31{
    margin: 41px 38px;
  }
  .glh1{
    font-size: 24px;
    margin-bottom: 10px;
  }
  .glh2{
    font-size: 24px;
    margin-top: 115px;
    div{
      margin-top: 35px;
    }
  }
  .glbg1{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url("../../assets/park/glbg1.png");
    background-size: 100% 100%;
    vertical-align: middle;
    margin-top: -5px;
    margin-right: 10px;
  }
  .glbg2{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url("../../assets/park/glbg2.png");
    background-size: 100% 100%;
    vertical-align: middle;
    margin-top: -5px;
    margin-right: 10px;
  }
  .glbg3{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url("../../assets/park/glbg3.png");
    background-size: 100% 100%;
    vertical-align: middle;
    margin-top: -5px;
    margin-right: 10px;
  }
}
.glright{
  color: #fff;
  .glbottom{
    background: linear-gradient(119deg, #313F6D 0%, #23315F 100%);
    line-height: 102px;
    padding: 0 38px;
    .btn{
      float: right;
      display: inline-block;
      margin-top: 35px;
    }
  }
  .imglist{
    height: 172px;
    border-right:1px solid rgba(255,255,255,0.2);
    border-bottom:1px solid rgba(255,255,255,0.2);
    background: linear-gradient(119deg, #313F6D 0%, #23315F 100%);
    text-align: center;
  }
  .img{
    width: 64px;
    height: 64px;
    margin: 0px auto;
    padding-top: 38px;
    margin-bottom: 15px;
  }
}
.bg4{
  height: 86px;
  background: url("../../assets/park/bg4.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 40px;
}
.wenda{
  padding: 56px 0;
}
.shichang{
  border-radius: 2px;
  border: 1px solid #ddd;
  margin-top: 35px;
  .scleft{
    width: 420px;
    .xuhao{
      margin-right: 10px;
    }
    .ul{
      padding: 26px 32px;
      cursor: pointer;
    }
    .checked{
      background: #fff;
    }
  }
  .scright{
    background: #fff;
    line-height: 32px;
    .deatal{
      margin: 38px 48px;
    }
    .sctitle{
      margin-bottom: 32px;
      font-size: 18px;
      font-weight: bolder;
    }
    .scda{
      margin-bottom: 32px;
      color: #808080;
    }
    .scdas{
      height: 400px;
      overflow: auto;
    }
    .wen{
      width: 32px;
      height: 32px;
      border-radius: 6px;
      background: rgba(0, 121, 233, 0.10);
      color: #0079E9;
      font-size: 16px;
      vertical-align: middle;
      text-align: center;
      line-height: 32px;
      margin-right: 20px;
    }
    .da{
      width: 32px;
      height: 32px;
      border-radius: 6px;
      background: #0079E9;
      color: #fff;
      font-size: 16px;
      vertical-align: middle;
      text-align: center;
      line-height: 32px;
      margin-right: 20px;
    }
  }
}
.bg{
  background: #fff;
  padding:56px 0 ;
}
.bg1{
  height: 200px;
  background: url("../../assets/park/bg1.png") no-repeat;
  background-size: 100% 100%;
}
.bg2{
  background: url("../../assets/park/bg2.png") no-repeat;
  background-size: 100% 100%;
  padding: 56px 0;
}
.zhongdian{
  overflow: hidden;
  margin-top: 40px;
  .bg3{
    background: url("../../assets/park/bg3.png") no-repeat;
    background-size: 100% 100%;
    width: 167px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    border-radius: 4px;
    margin: 2px;
    color: #fff;
    float: left;
  }
}
.zhanshi{
  overflow: hidden;
  background: #fff;
  margin-top: 24px;
  border-radius: 6px;
  .list{
    width: 264px;
    margin: 16px 18px;
    float: left;
    cursor: pointer;
    .listtitle{
      font-weight: bolder;
      font-size: 16px;
      line-height: 50px;
    }
    .cor{
      color: #808080;
    }
    .el-icon-phone-outline,.el-icon-location-information{
      margin-right: 8px;
    }
    .images{
      width:264px;
      height: 177px;
    }
  }
  .list:nth-child(1){
    margin: 16px 18px 16px 12px;
  }
  .list:nth-last-child(1){
    margin: 16px 12px 16px 18px;
  }
}
.buton{
  width: 800px;
  margin: 0 auto;
}
.tab{
  width: 310px;
  height: 85px;
  background: #FFFFFF;
  color: #1D1D1D;
  border-radius: 6px;
  margin-top: 57px;
  text-align: center;
  cursor: pointer;
  .tabh1{
    font-size: 20px;
    font-weight: bolder;
    padding: 10px 0;
  }
}
.mgr135{
  margin: 57px 135px;
  margin-right: 0;
}
.title{
  font-size: 20px;
  font-weight: bolder;
  .more{
    color: #1D1D1D;
    font-size: 14px;
    font-weight: normal;
    float: right;
    cursor: pointer;
  }
}
.textalgin{
  text-align: center;
}
.title1{
  font-size: 28px;
  font-weight: bolder;
  padding: 32px 0;
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  .title2{
    color: #999;
    font-size: 16px;
    line-height: 45px;
  }
}
.zhuti{
  width: 1200px;
  margin:0px auto;
  .sxx{
    background: #fff;
    padding: 20px;
  }
}

.baogao{
      position: relative;
    }
    .huaxiangzones{
  /deep/.el-radio-button__inner{
    width:100px;
  }
}
</style>