<template>
  <div class="huaxiang" style="padding-top:30px;">
    <!-- <FangziHeadTwo ttPass="企业画像"></FangziHeadTwo> -->
    <!-- <div style="padding: 20px 50px;overflow: hidden">
    </div> -->
    <div   style="text-align: center;">
      <div style="line-height: 80px;font-weight: 550;font-size:20px;color:#2E2E2E;">产业企业画像</div>
      <div   style="width: 800px;margin: 0 auto">
   
        <el-autocomplete
        style="width:660px"
    v-model.trim="state"
    :fetch-suggestions="querySearch"
    placeholder="请输入企业名称关键字"
    @select="handleSelect"
    clearable
    :disabled="!common.getSessionStorage('userInfo')"
  >
  <el-button slot="append" icon="el-icon-search"  @click="btnSearch" :disabled="isLoading"></el-button>
</el-autocomplete>
      </div>
    </div>
    <div style="margin: 50px 105px 0px;background: #fff;padding: 30px;">
      <div   style="font-size: 12px">共检索到<span style="color:#1E90FF;">{{totalPassSY}}</span>家企业</div>
      <div class="list" v-for="(item,index) in gridDataShangyou" :key="index" @click="goxq(item.id)">
          <div class="h1">{{item.name}}</div>
          <div class="flex h2">
            <div class="flex-1">注册资本 ： {{ item.regAmount==-1?'暂无数据':item.regAmount+"万元" }}）</div>
            <div class="flex-1">成立时间 ： {{item.setupDate}}</div>
            <div class="flex-1">注册地址：{{item.address}}</div>
          </div>
      </div>
      <div class="fenye" v-if="totalPassSY!=0">
        <PaginationCom :totalPass="totalPassSY" :currentPagePass="currentPagePassSY" @handleCurrentChangeBack="handleCurrentChangeBackSY" :backgroundPass="true" :layoutPass="'prev, pager, next'"></PaginationCom>
      </div>
    </div>
  </div>
</template>
<script>
import companytab from '../../../components/map/companytab'
// import {enterprisePageList,enterpriseSearchName} from '../../../api/map/common'
import {enterprisePageList,enterpriseSearchName} from "../../../request/moudle/map/common"


import PaginationCom from '../../../components/zkwl/PaginationCom/PaginationCom.vue'
import FangziHeadTwo from "../../../components/zkwl/FangziHeadTwo.vue";
export default {
  name: "face",
  data(){
    return {
      totalPassSY:0,
      currentPagePassSY:1,
      currentPageSize:10,
      keyword:'',
      gridDataShangyou:[],
      //
      state: '',
      timeout: null,
      suggestions: [],
      isLoading:false,
      //
      // pageNo:1,
      // total:60
    }
  },
  created() {
    // this.$store.commit("CHANGE_TABTYPE", 0);
    // this.$bus.$on("refresh_Huaxiang", (val) => {
	  //     console.log(val)   // 0.7022180283884656
    //     // this.$message.error('跳转');
    //     // this.initPage();
    //     const obj = sessionStorage.getItem("parampass_huaxiang");
    // console.log("企业参数", obj);
    // if (obj == undefined) {
    //   this.getSYList();
    // } else {
    //   // this.ss_companypg = JSON.parse(obj);
    //   const info=JSON.parse(obj);
    //   this.state=info.key;
    //   this.getSYList();
    // }
	  //   });
  },
  mounted(){
    const obj = sessionStorage.getItem("parampass_huaxiang");
    console.log("企业参数", obj);
    if (obj == undefined) {
      this.getSYList();
    } else {
      // this.ss_companypg = JSON.parse(obj);
      const info=JSON.parse(obj);
      this.state=info.key;
      this.getSYList();
    }

  },
  components:{
    companytab,
    PaginationCom,
    FangziHeadTwo,
  },
  methods:{
 
    btnSearch(){
      this.gridDataShangyou=[];
   
      this.currentPagePassSY=1;
      this.getSYList();
    },
    querySearch(queryString, cb) {
      const that=this;
      // 清除定时器
      clearTimeout(this.timeout);
      if(that.state==''){
        return;
      }
      this.timeout = setTimeout(() => {
        // 模拟从后台获取数据的异步操作
        enterpriseSearchName({key:queryString}).then(res=>{
      
          const names=res.data.map(item=>{
            return {value:item.name,id:item.id} ;
          })
            that.suggestions = names;
      //       // 调用 callback 返回建议列表的数据
            cb(that.suggestions);
        }).catch(err=>{
          cb([]);
        });
      }, 500); // 延迟200毫秒请求，防止密集的请求
      // this.timeout = setTimeout(() => {
      //   // 模拟从后台获取数据的异步操作
      //   axios.get('your-api-url', { params: { q: queryString } })
      //     .then(response => {
      //       // 假设返回的数据格式为 { data: ['Option 1', 'Option 2', ...] }
      //       this.suggestions = response.data.data;
      //       // 调用 callback 返回建议列表的数据
      //       cb(this.suggestions);
      //     })
      //     .catch(error => {
      //       console.error(error);
      //       cb([]);
      //     });
      // }, 200); // 延迟200毫秒请求，防止密集的请求
    },
    handleSelect(item) {
      // 选中某项时的处理逻辑
    },
    handleClear(){
      this.currentPagePassSY=1;
      this.getSYList();
    },
    //搜索按钮
    btnEnts(){
      this.currentPagePassSY=1;
      this.getSYList();
    },
        //获取上游列表
        getSYList(){
     let query={...{size:this.currentPageSize,current:this.currentPagePassSY}}
          this.isLoading=true;
     enterprisePageList({...{key:this.state},...query}).then(res=>{
      this.isLoading=false;
       const res2=res.data;
      this.gridDataShangyou=res2.records;
      this.totalPassSY=res2.total;
     }).catch(err=>{
      this.isLoading=false;
     });
   },
        //当前页发生变化
        handleCurrentChangeBackSY(val){
this.currentPagePassSY=val;
this.getSYList();
},
    goxq(idP){
      // sessionStorage.removeItem('parampass_companyxq');
        // sessionStorage.setItem('parampass_companyxq',JSON.stringify({id:idP}));
      this.$router.push({path: "/companyxq",query:{
        id:idP
      }});
    }
  }
}
</script>
<style lang="scss" scoped>
.huaxiang{
  font-size: 14px;
  background: url("../../../assets/img/map/sousuo.jpg");
  /* //将图片样式不重复 */
  background-repeat: no-repeat;
  /* //设置图片大小 */
  // background-size: 100%;
    position: relative;
    background-size:100% 100%;
    min-height: calc( 100vh - 120px);
}
.list{
  padding: 17px 10px;
  border-bottom: 1px solid #DFDFDF;
  font-size: 12px;
  .h1{
    font-weight: 550;
    line-height: 26px;
    font-size:14px;
  }
}
.fenye{
  text-align: center;
  padding: 30px 0 0 0;
}
</style>
