<template>
  <div :style="'text-align:'+pstPass+';margin-right:20px;margin-bottom:'+mbPass+'px;'">
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="currentPagePass"
      :page-size="currentPageSize"
      :layout="layoutPass"
      :total="totalPass"
      :background="backgroundPass"
      :pager-count="pagercountPass"
      :small="smallPass">
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: 'subButton',
  data() {
    return {
      loading: false,
    }
  },
  props: {
    pagercountPass: {
      type: Number,
      default: 19,
    },
    smallPass: {
      type: Boolean,
      default: false,
    },
    // 总数
    totalPass: {
      type: Number,
      default: 0,
    },
    //当前页
    currentPagePass: {
      type: Number,
      default: 0,
    },
    //页容量
    currentPageSize:{
      type: Number,
      default: 10,
      // default: 100,
    },
    backgroundPass:{
      type: Boolean,
      default: false,
    },
    pstPass:{
      type: String,
      default: 'center',
      // default: 100,
    },
    //下边距
    mbPass: {
      type: Number,
      default: 0,
    },
    layoutPass:{
      type: String,
      default: 'total, prev, pager, next',
      // default: 100,
    },

    
  },
  methods:{
    //当前页变化回调
    handleCurrentChange(val){
     
      this.$emit("handleCurrentChangeBack", val);
    }
  }
}
</script>