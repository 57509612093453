<template>
  <div class="huaxiang" style="padding-top:30px;">
    <!-- <FangziHeadTwo ttPass="企业管理"></FangziHeadTwo> -->
    <div style="padding: 20px 50px;overflow: hidden">
    </div>
 <div style="margin:0 50px;"> 
  <p class="title fs14">我的关注名单列表</p>
  <div> 


    <div > 
      <div  v-for="(item,index) in tableDataMingdan" :key="item.id"  > 
        <div class="lsmingdan md" > 
          <el-row>
  <el-col :span="5"><div class="" style="margin-left:65px;">
    <p class="pnomp" style="margin-top:20px;">所属名单</p>
    <div style="background-color: #007AF3;color:white;width:50%;text-align: center;"> 
      <p class="pnomp ellipsis" style="margin-top:24px;padding:8px 0;" :title="item.name">{{item.name}}</p>
    </div>
  </div></el-col>
  <el-col :span="5"><div class="" style="margin-left:33px;"> 
    <p class="pnomp" style="margin-top:20px;">名称添加日期</p>
    <div> 
      <p class="pnomp" style="margin-top:34px;">{{item.createTime}}</p>
    </div>
  </div></el-col>
  <el-col :span="5"><div class="" style="margin-left:33px;">
    <p class="pnomp" style="margin-top:20px;">名单领取企业数量（家）</p>
    <div> 
      <p class="pnomp" style="margin-top:34px;">{{item.enterpriseQty}}</p>
    </div>
  </div></el-col>
  <el-col :span="6"><div class="">
    <div style="margin-top:45px;"> 
      <el-button type="primary" @click="handleClickEdit(item.id,item.name)"  size="mini" style="margin-right:20px;">编辑</el-button>
      <el-popconfirm
  title="删除名单会删除所有企业？"
  @confirm="btnDeleteFav"
>
<el-button type="danger" slot="reference" @click=" handleClickDelete(item.id)" size="mini" style="margin-right:20px;">删除</el-button>
</el-popconfirm>
    </div>
  </div></el-col>
  <el-col :span="3"><div class="" style="position:relative;">
    <el-image
      style="width: 50px; height: 50px;margin-top:35px;"
      :src="require( `@/assets/img/map/ccmanage.png`)"
      :fit="'cover'"></el-image>
      <el-image
      style="width: 30px; height: 15px;position: absolute;transform: rotate(0deg);"
      :style="nowMingdan!=index?'top:55px;left:10px;':'transform: rotate(180deg);top:50px;left:10px;'"
      :src="require( `@/assets/img/map/arrowbai.png`)"
      :fit="'cover'" @click="btnSon(index,item.id)"></el-image>
  </div></el-col>

</el-row>
        </div>
     
<!--  -->

<div v-if="nowMingdan==index" style="height:580px;background-color: white;display: block;margin-bottom:10px;padding:0 25px 0px 25px;border-radius:10px;border:1px solid #AEAEAE;"> 
  <el-popconfirm
  title="移除所选所有企业？"
  @confirm="btnRemoveEnts"
>
<el-button type="danger" slot="reference" @click="handleClickDeleteMany()" size="mini" style="margin-right:20px;margin:4px 20px 4px 25px;">移除所选企业</el-button>
</el-popconfirm>
  <el-table :data="tableDataEnts" @selection-change="handleSelectionChange" height="500">
    <el-table-column
      type="selection"
      width="55">
    </el-table-column>
    <el-table-column property="name" label="企业名称" width="350"></el-table-column>
    <el-table-column property="setupDate" label="成立时间" ></el-table-column>
    <el-table-column property="regAmount" label="注册资本"></el-table-column>
    <el-table-column property="phone" label="联系方式"></el-table-column>
    <el-table-column property="address" label="经营状态"></el-table-column>
  </el-table>
  <PaginationCom :totalPass="totalPassXY" :currentPagePass="currentPagePassXY" @handleCurrentChangeBack="handleCurrentChangeBackXY" :backgroundPass="true"></PaginationCom>
</div>

<!--  -->
      </div>
      <div style="background-color: white;margin-top: 4px;"> 
      <PaginationCom :totalPass="totalPassSY" :currentPagePass="currentPagePassSY" @handleCurrentChangeBack="handleCurrentChangeBackSY" :backgroundPass="true"></PaginationCom>
  </div>
    </div>
    <div class="fenye"> 

    </div>



    <!-- <el-table
      :data="tableDataMingdan"
      height="580"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="所属名单"
        width="380">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="名单添加日期"
        width="280">
      </el-table-column>
      <el-table-column
        prop="enterpriseQty"
        label="名单关注企业数量（家）"
        width="280">
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作">
        <template slot-scope="scope">
        <el-button type="primary" @click="handleClickEdit(scope.row.id,scope.row.name)"  size="mini" style="margin-right:20px;">编辑</el-button>
        <el-popconfirm
  title="删除名单会删除所有企业？"
  @confirm="btnDeleteFav"
>
<el-button type="danger" slot="reference" @click=" handleClickDelete(scope.row.id)" size="mini" style="margin-right:20px;">删除</el-button>
</el-popconfirm>
        <el-button @click="handleClickEnts(scope.row.id)"  size="mini" style="margin-right:20px;">查看企业</el-button>
      </template>
      </el-table-column>
    </el-table>
    <div style="background-color: white;margin-top: 4px;"> 
      <PaginationCom :totalPass="totalPassSY" :currentPagePass="currentPagePassSY" @handleCurrentChangeBack="handleCurrentChangeBackSY" :backgroundPass="true"></PaginationCom>
  </div> -->
    </div>
 </div>
 <el-dialog title="企业列表" :visible.sync="dialogTableVisible" :append-to-body="true">
  <el-popconfirm
  title="移除所选所有企业？"
  @confirm="btnRemoveEnts"
>
<el-button type="danger" slot="reference" @click="handleClickDeleteMany()" size="mini" style="margin-right:20px;">移除所选企业</el-button>
</el-popconfirm>
  <el-table :data="tableDataEnts" @selection-change="handleSelectionChange" height="500">
    <el-table-column
      type="selection"
      width="55">
    </el-table-column>
    <el-table-column property="name" label="企业名称" width="150"></el-table-column>
    <el-table-column property="setupDate" label="成立时间" width="200"></el-table-column>
    <el-table-column property="regAmount" label="注册资本"></el-table-column>
    <el-table-column property="phone" label="联系方式"></el-table-column>
    <el-table-column property="" label="经营状态"></el-table-column>
  </el-table>
  <PaginationCom :totalPass="totalPassXY" :currentPagePass="currentPagePassXY" @handleCurrentChangeBack="handleCurrentChangeBackXY" :backgroundPass="true"></PaginationCom>
</el-dialog>
<el-dialog title="名单名称" :visible.sync="dialogFormVisible" :append-to-body="true">
  <el-form :model="form">
    <el-form-item label="名称" >
      <el-input v-model.trim="form.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="btnEditFav">确 定</el-button>
  </div>
</el-dialog>
  </div>
</template>
<script>
import companytab from '../../../components/map/companytab'
import PaginationCom from '../../../components/zkwl/PaginationCom/PaginationCom.vue'
import FangziHeadTwo from "../../../components/zkwl/FangziHeadTwo.vue";
// import {myCollectNameList,updateReceiveName,deleteReceiveName,myEnterpriseReceiveList,deleteReceiveEnterprise} from "../../../api/map/huaxiang"
import {myCollectNameList,updateReceiveName,deleteReceiveName,myEnterpriseReceiveList,deleteReceiveEnterprise} from "../../../request/moudle/map/huaxiang"
export default {
  name: "entmanage",
  data(){
    return {
      totalPassSY:0,
      currentPagePassSY:1,
      currentPageSize:10,
      // formShangyou:{},//条件查询对象
      totalPassXY:0,
      currentPagePassXY:1,
      currentPageSize:10,
      // formXiayou:{},//条件查询对象
      //
      tableDataMingdan: [],//名单列表
        dialogTableVisible: false,//企业列表对话框
        dialogFormVisible:false,//编辑对话框
        form:{
          name:null
        },//编辑名单
        nowFavId:null,//当前名单id
        multipleSelection: [],//多选
        tableDataEnts: [
        ],//企业列表
        nowDirId:null,//当前的
        nowMingdan:null,
    }
  },
  created() {
    // this.$store.commit("CHANGE_TABTYPE", 3);
  },
  mounted(){

    this.getCollectName();
  },
  components:{
    companytab,
    PaginationCom,
    FangziHeadTwo,
  },
  methods:{
    //打开企业列表
    btnSon(indexP,idP){
            //状态初始化
            this.tableDataEnts=[];
        this.multipleSelection=[];
        this.currentPagePassXY=1;
        this.nowDirId=null;
        //
      if(this.nowMingdan==null){
        this.nowMingdan=indexP;
        this.nowDirId=idP;
        this.getEntsList();
      }else if(this.nowMingdan==indexP){
        this.nowMingdan=null;
  
        //
      }else{
        this.nowMingdan=indexP;
        this.nowDirId=idP;
        this.getEntsList();
      }
   
    },
    //获取企业列表
    getEntsList(){
      let query={...{id:this.nowDirId},...{size:this.currentPageSize,current:this.currentPagePassXY}}
      myEnterpriseReceiveList(query).then(res=>{
       
        this.tableDataEnts = res.data.records.map(item=>{
          return {...item,...{regAmount:item.regAmount==-1?'暂无数据':item.regAmount}}
        }) ||[];
        this.totalPassXY=res.data.total;
    }).catch();
    },
    //查询名单列表
    getCollectName(){
      let query={...{size:this.currentPageSize,current:this.currentPagePassSY}}
      myCollectNameList(query).then(res=>{
        this.tableDataMingdan=res.data.records.map(item=>{
          return {
            ...item,...{isOpen:true}
          }
        });
        this.totalPassSY=res.data.total;
    }).catch();
    },
    //移除企业
    btnRemoveEnts(){
      const ids=this.multipleSelection.map(item=>{
        return item.id;
      })
      if(ids.length==0){
        this.$message.error('请选择要移除的企业');
        return;
      }
      deleteReceiveEnterprise({idList:ids}).then(res=>{
        if(res.data.code==200){
          this.$message.success('移除企业成功');
          this.multipleSelection=[];
          this.getEntsList();
        }
      }).catch()
    },
    //多选
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
       //当前页发生变化
       handleCurrentChangeBackSY(val){
this.currentPagePassSY=val;
this.getCollectName();
},
  //当前页发生变化
handleCurrentChangeBackXY(val){
this.currentPagePassXY=val;
this.getEntsList();
},
    //编辑名单名字
    btnEditFav(){
      if(this.form.name!=''){
        updateReceiveName({id:this.nowFavId,name:this.form.name}).then(res=>{
          if(res.data.code==200){
            this.$message.success('编辑名称成功');
            this.dialogFormVisible=false;
            this.getCollectName();
          }
        }).catch();
      }else{
        this.$message.error('请输入名称');
      }
    },
    //删除收藏夹
    btnDeleteFav(){
      //调用真实接口
      deleteReceiveName({id:this.nowFavId}).then(res=>{
        if(res.data.code==200){
          this.$message.success('删除名单成功');
          this.getCollectName();
          
        this.nowMingdan=null;
        }
      }).catch()
    },
    //编辑
    handleClickEdit(idP,nameP){
      this.nowFavId=idP;
      this.dialogFormVisible=true;
      this.form.name=nameP;
    },
    //删除
    handleClickDelete(idP){
      this.nowFavId=idP;
    },
    handleClickDeleteMany(){
    },
    //查看企业列表
    handleClickEnts(idP){
      this.nowDirId=idP;
      this.dialogTableVisible=true;
      //初始化数据
      this.currentPagePassXY=1;
      this.tableDataEnts=[];
      this.multipleSelection=[];
      this.getEntsList();
    },
  }
}
</script>
<style lang="scss" scoped>
.huaxiang{
  font-size: 14px;
  background: url("../../../assets/img/map/sousuo.jpg");
  /* //将图片样式不重复 */
  background-repeat: no-repeat;
  /* //设置图片大小 */
  // background-size: 100%;
    position: relative;
    background-size:100% 100%;
    min-height: calc( 100vh - 120px);
}
.list{
  padding: 17px 10px;
  border-bottom: 1px solid #DFDFDF;
  font-size: 12px;
  .h1{
    font-weight: 550;
    line-height: 26px;
  }
}
.fenye{
  text-align: center;
  padding: 30px 0 0 0;
}
.title{
  font-weight: bold;
}

.md{
  height:120px;
  border:1px solid #AEAEAE;
  border-radius: 10px;
  background-color: white;
  margin:0 0 6px 0;
}
</style>
