<template>
  <div>
    <el-radio-group v-model="tabtype" style="float: right"   @input="goto" >
      <el-radio-button label="0">企业画像</el-radio-button>
      <el-radio-button label="1">关联图谱</el-radio-button>
      <el-radio-button label="2">评价报告</el-radio-button>
      <el-radio-button label="3">企业管理</el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "companytab",
  data(){
    return  {
    }
  },
  computed:{
    ...mapGetters(["tabtype"]),
  },
  methods:{
    goto(num){
        this.$store.commit("CHANGE_TABTYPE", num);
        if(num==0){
          this.$router.push({
            path:'/map/huaxiang'
          })
        }else if(num==1){
          this.$router.push({
            path:'/map/gltupu'
          })
        }else if(num==2){
          // this.$router.push({
          //   path:'/map/baogao'
          // })
        }else if(num==3){
          this.$router.push({
            path:'/map/entmanage'
          })
        }
    }
  }
}
</script>

<style scoped>

</style>
